import ReactGA from "react-ga";

export function participate(
  event_id,
  name,
  email,
  message,
  phone,
  pre_api_callback,
  then_callback,
  error_callback,
  finally_callback,
  context
) {
  pre_api_callback();
  const data = {
    name: name,
    email: email,
    message: message,
    phone: phone
  };

  global.api
    .post(`/api/v1/user/participate/${event_id}`, data)
    .then(result => {
      then_callback(result);
      context.addMessage(
        "success",
        "Přihlášení proběhlo úspěšně. Na email jsme Vám zaslali potvrzení."
      );
      ReactGA.event({ category: "Participation", action: "Create" });
    })
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        ReactGA.event({
          category: "Error",
          action: "Participation",
          label: error.response.toString()
        });
        switch (error.response.status) {
          case 404:
            context.addMessage(
              "danger",
              "Přihlášení se nezdařilo. Termín události nebyl nalezen."
            );
            break;
          default:
            context.addMessage(
              "danger",
              "Přihlášení se nezdařilo. Zkuste to prosím znovu."
            );
        }
      } else if (error.request) {
        ReactGA.event({
          category: "Error",
          action: "Participation",
          label: error.request.toString()
        });
        console.log(error.request);
      } else {
        ReactGA.event({
          category: "Error",
          action: "Participation",
          label: error.message
        });
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      error_callback(error);
    })
    .finally(() => finally_callback());
}

export function cancel_participation(
  participation_id,
  email,
  pre_api_callback,
  then_callback,
  error_callback,
  finally_callback,
  context
) {
  pre_api_callback();
  global.api
    .post("/api/v1/user/participation/" + participation_id, { email: email })
    .then(res => {
      then_callback(res);
      ReactGA.event({
        category: "Participation",
        action: "Delete",
        label: "Deleted by user"
      });
    })
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        ReactGA.event({
          category: "Error",
          action: "Participation",
          label: error.response.toString()
        });
        switch (error.response.status) {
          case 404:
            context.addMessage(
              "danger",
              "Při rušení rezervace nastala chyba. Rezervace nebyla nalezena."
            );
            break;
          default:
            context.addMessage(
              "danger",
              "Při rušení rezervace nastala chyba. Zkuste to prosím znovu."
            );
        }
      } else if (error.request) {
        ReactGA.event({
          category: "Error",
          action: "Participation",
          label: error.request.toString()
        });
        console.log(error.request);
      } else {
        ReactGA.event({
          category: "Error",
          action: "Participation",
          label: error.message
        });
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      error_callback(error);
    })
    .finally(() => finally_callback());
}

export function getParticipantsByEvent(
  eventId,
  pre_api_callback,
  then_callback,
  error_callback,
  finally_callback,
  context
) {
  pre_api_callback();
  global.api
    .get("/api/v1/admin/participants/" + eventId)
    .then(res => then_callback(res))
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        ReactGA.event({
          category: "Error",
          action: "Participation",
          label: error.response.toString()
        });
        switch (error.response.status) {
          case 401:
            context.addMessage(
              "danger",
              "Vaše přihlášení vypršelo. Přihlaste se prosím znovu."
            );
            context.signOut();
            break;
          default:
            context.addMessage(
              "danger",
              "Při načítání nastala chyba. Zkuste to prosím znovu."
            );
        }
      } else if (error.request) {
        ReactGA.event({
          category: "Error",
          action: "Participation",
          label: error.request.toString()
        });
        console.log(error.request);
      } else {
        ReactGA.event({
          category: "Error",
          action: "Participation",
          label: error.message
        });
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      error_callback(error);
    })
    .finally(() => finally_callback());
}

export function deleteParticipation(
  participationId,
  notify = false,
  pre_api_callback,
  then_callback,
  error_callback,
  finally_callback,
  context
) {
  pre_api_callback();
  global.api
    .delete("/api/v1/admin/participation/" + participationId, {
      data: {
        notify_subscriber: notify
      }
    })
    .then(res => {
      then_callback(res);
      ReactGA.event({
        category: "Participation",
        action: "Delete",
        label: "Deleted by admin"
      });
    })
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        ReactGA.event({
          category: "Error",
          action: "Participation",
          label: error.response.toString()
        });
        switch (error.response.status) {
          case 401:
            context.addMessage(
              "danger",
              "Vaše přihlášení vypršelo. Přihlaste se prosím znovu."
            );
            context.signOut();
            break;
          case 403:
            context.addMessage(
              "danger",
              "Pro zrušení rezervace nemáte dostatečná oprávnění."
            );
            break;
          case 404:
            context.addMessage(
              "danger",
              "Při rušení rezervace nastala chyba. Rezervace nebyla nalezena."
            );
            break;
          default:
            context.addMessage(
              "danger",
              "Při rušení rezervace nastala chyba. Zkuste to prosím znovu."
            );
        }
      } else if (error.request) {
        ReactGA.event({
          category: "Error",
          action: "Participation",
          label: error.request.toString()
        });
        console.log(error.request);
      } else {
        ReactGA.event({
          category: "Error",
          action: "Participation",
          label: error.message
        });
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      error_callback(error);
    })
    .finally(() => finally_callback());
}
