import React from "react";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import { AlertBarContext } from "../AlertBarContextProvider";
import ApiCallButton from "../components/ApiCallButton";
import { Redirect } from "react-router-dom";
import { resetPassword } from "../Api/ChangePassword";
import ReactGA from "react-ga";

class ForgotPasswordRequestPage extends React.Component {
  static contextType = AlertBarContext;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      password: "",
      remember: false,
      done: null,
      isSent: false
    };
    ReactGA.pageview("/signin/reset_request");
  }

  handleSubmit = async event => {
    event.preventDefault();
    resetPassword(
      this.state.email,
      () => this.setState({ isLoading: true }),
      () => this.setState({ isSent: true, done: "done" }),
      () => this.setState({ done: "failed" }),
      () => this.setState({ isLoading: false }),
      this.context
    );
  };

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  renderForm() {
    return (
      <>
        {this.state.done === "failed" ? this.renderForm() : ""}
        <Form onSubmit={this.handleSubmit}>
          <Form.Group controlId="email">
            <Form.Label>
              Víme, že ztráta hesla je velice nepříjemná situace. Proto se Vám
              pokusíme vydat nové, jak rychle to jen půjde.
            </Form.Label>
            <Form.Control
              type="email"
              placeholder="Přihlašovací email"
              required
              onChange={this.handleChange}
            />
          </Form.Group>

          <ApiCallButton
            variant="primary"
            type="submit"
            className="mb-2"
            waiting={this.state.isLoading}
            text="Resetovat heslo"
            textOnApiCall="Odesílám email ..."
          />
        </Form>
      </>
    );
  }

  render() {
    if (this.context.state.isSignedIn) {
      return <Redirect to="/admin" />;
    } else {
      return (
        <Container className="d-flex align-items-center justify-content-center h-50">
          <Col xs="12" sm="10" md="8" lg="6" xl="5">
            <legend className="border-bottom mb-4">Zapomenuté heslo</legend>
            {this.state.isSent ? <p>Formulář odeslán.</p> : this.renderForm()}
          </Col>
        </Container>
      );
    }
  }
}

export default ForgotPasswordRequestPage;
