import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import Events from "../components/Events";
import { AlertBarContext } from "../AlertBarContextProvider";
import GoBackButton from "../components/GoBackButton";
import { getEventsByEventGroup } from "../Api/Event";
import { getEventGroup } from "../Api/EventGroup";
import { loadOrganizationByEventGroup } from "../Api/Organization";
import ReactGA from "react-ga";

class EventGroupPage extends React.Component {
  static contextType = AlertBarContext;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isLoadingOrg: true,
      eventGroupName: null,
      eventGroupDesc: null,
      eventGroupLocked: false,
      eventGroupType: null,
      events: null,
      eventGroupId: props.match.params.eventGroupId,
      organizationName: null,
      organizationEmail: null,
      organizationId: null
    };
    ReactGA.pageview("/admin/events");
  }

  componentDidMount() {
    this.loadData();
    this.loadOrganizationData();
  }

  loadOrganizationData = () => {
    loadOrganizationByEventGroup(
      this.state.eventGroupId,
      () => this.setState({ isLoadingOrg: true }),
      res =>
        this.setState({
          organizationEmail: res.data.email,
          organizationId: res.data.id,
          organizationName: res.data.name
        }),
      () => {},
      () => this.setState({ isLoadingOrg: false }),
      this.context
    );
  };

  loadData = () => {
    getEventGroup(
      this.state.eventGroupId,
      () => this.setState({ isLoading: true }),
      res => {
        this.setState({
          eventGroupName: res.data.name,
          eventGroupDesc: res.data.description,
          eventGroupType: res.data.event_group_type,
          eventGroupLocked: res.data.locked
        });
        getEventsByEventGroup(
          this.state.eventGroupId,
          () => {},
          res => this.setState({ events: res.data }),
          () => {},
          () => this.setState({ isLoading: false }),
          this.context
        );
      },
      () => {},
      () => {},
      this.context
    );
  };

  render() {
    return (
      <Container className="d-flex justify-content-center">
        <Col xs="12" sm="10" md="10" lg="10" xl="10">
          <legend className="border-bottom mb-4">
            <GoBackButton /> {this.state.eventGroupName}
          </legend>
          <p>{this.state.eventGroupDesc}</p>
          <p>Škola: {this.state.organizationName}</p>
          {this.state.isLoading ? (
            <Spinner animation="grow" variant="primary" />
          ) : (
            <>
              {this.state.eventGroupLocked ? (
                <div>Registrace je uzavřena.</div>
              ) : (
                <></>
              )}
              <Events
                key={`eventgroup-${this.state.eventGroupId.toString()}`}
                events={this.state.events}
                isLoading={this.state.isLoading}
                eventGroupType={this.state.eventGroupType}
                eventGroupLocked={this.state.eventGroupLocked}
              />
            </>
          )}
        </Col>
      </Container>
    );
  }
}

export default EventGroupPage;
