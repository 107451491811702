import React from "react";
import GoBackButton from "../GoBackButton";
import Form from "react-bootstrap/esm/Form";
import ApiCallButton from "../ApiCallButton";
import { FaCheckImage, FaPenImage } from "../Icons";
import Spinner from "react-bootstrap/Spinner";
import { modifyEventGroup } from "../../Api/EventGroup";

class EventGroupName extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      requestSent: false,
      editMode: false,
      editText: props.name
    };
  }

  onButtonClick = () => {
    const editMode = !this.state.editMode;
    if (editMode === false) {
      this.modifyEventGroupNameCall(this.state.editText);
    }
    this.setState({ editMode: editMode });
  };

  modifyEventGroupNameCall = name => {
    modifyEventGroup(
      this.props.eventGroupId,
      name,
      null,
      () => this.setState({ updatingValue: true, editMode: false }),
      res => {
        this.setState({ editText: name });
        this.props.onNameChange(name);
      },
      () => {},
      () => this.setState({ updatingValue: false }),
      this.context
    );
  };

  render() {
    return (
      <>
        <GoBackButton />
        {this.state.editMode ? (
          <Form.Control
            type="text"
            className="w-50 d-inline"
            value={this.state.editText}
            onChange={event => {
              this.setState({ editText: event.target.value });
            }}
          />
        ) : (
          this.state.editText
        )}
        <ApiCallButton
          onClick={this.onButtonClick}
          variant="link"
          text={
            this.state.editMode ? (
              <FaCheckImage className="basic-icon" />
            ) : (
              <FaPenImage className="basic-icon" />
            )
          }
          textOnApiCall={<Spinner animation="grow" variant="primary" />}
          waiting={this.updatingValue}
        />
      </>
    );
  }
}
export default EventGroupName;
