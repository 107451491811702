import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import ListAllEventsGroups from "../components/ListAllEventsGroups";
import { AlertBarContext } from "../AlertBarContextProvider";
import { loadOrganization } from "../Api/Organization";
import ReactGA from "react-ga";

class OrganizationPage extends React.Component {
  static contextType = AlertBarContext;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      organizationName: null,
      organizationId: props.match.params.organizationId,
      organizationData: null
    };
    ReactGA.pageview("/organization");
  }

  componentDidMount() {
    this.loadEventGroupDetail();
  }

  loadEventGroupDetail = () => {
    loadOrganization(
      this.state.organizationId,
      () => this.setState({ isLoading: true }),
      res =>
        this.setState({
          organizationName: res.data.name,
          organizationData: res.data
        }),
      () => {},
      () => this.setState({ isLoading: false }),
      this.context
    );
  };

  renderOrganizationPage() {
    return (
      <Container className="d-flex justify-content-center">
        <Col xs="12" sm="10" md="10" lg="10" xl="10">
          <legend className="border-bottom mb-4">
            {this.state.organizationName}
          </legend>
          <ListAllEventsGroups organizationId={this.state.organizationId} />
        </Col>
      </Container>
    );
  }

  render() {
    return this.state.isLoading ? (
      <Spinner animation="grow" variant="primary" />
    ) : (
      this.renderOrganizationPage()
    );
  }
}

export default OrganizationPage;
