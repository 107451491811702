import React from "react";
import Alert from "react-bootstrap/Alert";
import { AlertBarContext } from "../AlertBarContextProvider";

class AlertBar extends React.Component {
  static contextType = AlertBarContext;

  render() {
    return this.context.state.messages.map((message, key) => (
      <Alert
        key={key}
        variant={message.type}
        dismissible={true}
        onClose={() => this.context.cleanMessage(message.message)}
      >
        {message.message}
      </Alert>
    ));
  }
}

export default AlertBar;
