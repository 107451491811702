import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import EventGroupDetail from "../components/EventGroupDetail/EventGroupDetail";
import { AlertBarContext } from "../AlertBarContextProvider";
import { Redirect } from "react-router-dom";
import ReactGA from "react-ga";

class AdminEventGroupDetailPage extends React.Component {
  static contextType = AlertBarContext;
  constructor(props) {
    super(props);

    this.state = {
      eventGroup: props.match.params.eventGroup,
      isLoading: true
    };
    ReactGA.pageview("/admin/event_group");
  }

  render() {
    if (this.context.state.isSignedIn) {
      return (
        <Container className="d-flex justify-content-center">
          <Col xs="12" sm="12" md="12" lg="12" xl="10">
            <EventGroupDetail eventGroupId={this.state.eventGroup} />
          </Col>
        </Container>
      );
    } else {
      return <Redirect to="/signin" />;
    }
  }
}

export default AdminEventGroupDetailPage;
