import React from "react";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import { AlertBarContext } from "../AlertBarContextProvider";
import ApiCallButton from "../components/ApiCallButton";
import { Redirect } from "react-router-dom";
import { changePasswordByToken } from "../Api/ChangePassword";
import ReactGA from "react-ga";

class ForgotPasswordFormPage extends React.Component {
  static contextType = AlertBarContext;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      password1: "",
      password2: "",
      token: props.match.params.token,
      remember: false,
      done: null,
      isChanged: false
    };
    ReactGA.pageview("/signin/reset_request");
  }

  handleSubmit = async event => {
    event.preventDefault();
    if (this.state.password1.length < 6) {
      this.context.addMessage("danger", "Heslo je příliš krátké.");
      return;
    }
    if (this.state.password1 !== this.state.password2) {
      this.context.addMessage("danger", "Zadaná hesla se neshodují.");
      return;
    }

    changePasswordByToken(
      this.state.token,
      this.state.password1,
      () => this.setState({ isLoading: true }),
      () => this.setState({ isSent: true, done: "done" }),
      () => this.setState({ password1: "", password2: "" }),
      () => this.setState({ isLoading: false }),
      this.context
    );
  };

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  renderForm() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Group controlId="password1">
          <Form.Control
            type="password"
            placeholder="Nové heslo"
            value={this.state.password1}
            required
            onChange={this.handleChange}
          />
        </Form.Group>
        <Form.Group controlId="password2">
          <Form.Control
            type="password"
            placeholder="Nové heslo pro kontrolu"
            value={this.state.password2}
            required
            onChange={this.handleChange}
          />
        </Form.Group>
        <ApiCallButton
          variant="primary"
          type="submit"
          className="mb-2"
          waiting={this.state.isLoading}
          text="Změnit heslo"
          textOnApiCall="Změna hesla probíhá ..."
        />
      </Form>
    );
  }

  render() {
    if (this.state.isChanged) {
      return <Redirect to="/signin" />;
    }
    if (this.context.state.isSignedIn) {
      return <Redirect to="/admin" />;
    } else {
      return (
        <Container className="d-flex align-items-center justify-content-center h-50">
          <Col xs="12" sm="10" md="8" lg="6" xl="5">
            <legend className="border-bottom mb-4">Změna hesla</legend>
            {this.renderForm()}
          </Col>
        </Container>
      );
    }
  }
}

export default ForgotPasswordFormPage;
