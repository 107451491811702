import React from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { AlertBarContext } from "../AlertBarContextProvider";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Spinner from "react-bootstrap/Spinner";
import Checkbox from "@material-ui/core/Checkbox";
import ApiCallButton from "./ApiCallButton";
import ApiCallButtonWithModal from "./ApiCallButtonWithModal";
import {
  FaBinImage,
  FaLockImage,
  FaUnlockImage,
  FaShareImage,
  FaPlusImage
} from "./Icons";
import Dropdown from "react-bootstrap/Dropdown";
import {
  deleteEventGroup,
  getEventGroupsAdmin,
  lockEventGroup,
  unlockEventGroup
} from "../Api/EventGroup";
import { InfoCircleFill } from "react-bootstrap-icons";

function truncateText(text, size) {
  return text.length > size ? text.slice(0, size - 1) + "…" : text;
}

class AdminListAllEventGroups extends React.Component {
  static contextType = AlertBarContext;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      eventGroups: [],
      selectedEventGroups: [],
      lockingEventGroups: [],
      unlockingEventGroups: [],
      deletingEventGroups: []
    };
  }

  componentDidMount() {
    this.loadEventGroups();
  }

  loadEventGroups = () => {
    getEventGroupsAdmin(
      () => this.setState({ isLoading: true }),
      res => {
        const eventGroups = res.data.map(eventGroup => {
          eventGroup.enableButton = true;
          return eventGroup;
        });
        const selected = Array(eventGroups.length).fill(false);
        this.setState({
          eventGroups: eventGroups,
          selectedEventGroups: selected
        });
      },
      () => {},
      () => this.setState({ isLoading: false }),
      this.context
    );
  };

  handleMarkAll = () => {
    if (this.state.selectedEventGroups.every(e => e === true)) {
      this.setState({
        selectedEventGroups: Array(this.state.selectedEventGroups.length).fill(
          false
        )
      });
    } else {
      this.setState({
        selectedEventGroups: Array(this.state.selectedEventGroups.length).fill(
          true
        )
      });
    }
  };

  unlockEventGroups = () => {
    const unlockingEventGroups = this.state.selectedEventGroups
      .map((el, i) => (el === true ? this.state.eventGroups[i].id : null))
      .filter(el => el !== null);
    this.setState({ unlockingEventGroups: unlockingEventGroups }, () =>
      unlockingEventGroups.forEach(eventGroup => {
        unlockEventGroup(
          eventGroup,
          () => {},
          () => {},
          () => {},
          () =>
            this.setState(
              {
                unlockingEventGroups: this.state.unlockingEventGroups.filter(
                  x => x !== eventGroup
                )
              },
              () => {
                if (this.state.unlockingEventGroups.length === 0)
                  this.loadEventGroups();
              }
            ),
          this.context
        );
      })
    );
  };

  lockEventGroups = () => {
    const lockingEventGroups = this.state.selectedEventGroups
      .map((el, i) => (el === true ? this.state.eventGroups[i].id : null))
      .filter(el => el !== null);
    this.setState({ lockingEventGroups: lockingEventGroups }, () =>
      lockingEventGroups.forEach(eventGroup => {
        lockEventGroup(
          eventGroup,
          () => {},
          () => {},
          () => {},
          () =>
            this.setState(
              {
                lockingEventGroups: this.state.lockingEventGroups.filter(
                  x => x !== eventGroup
                )
              },
              () => {
                if (this.state.lockingEventGroups.length === 0)
                  this.loadEventGroups();
              }
            ),
          this.context
        );
      })
    );
  };

  deleteEventGroups = (notify = false) => {
    const deletingEventGroups = this.state.selectedEventGroups
      .map((el, i) => (el === true ? this.state.eventGroups[i].id : null))
      .filter(el => el !== null);
    this.setState({ deletingEventGroups: deletingEventGroups }, () =>
      deletingEventGroups.forEach(eventGroup => {
        deleteEventGroup(
          eventGroup,
          notify,
          () => {},
          () => {},
          () => {},
          () =>
            this.setState(
              {
                deletingEventGroups: this.state.deletingEventGroups.filter(
                  x => x !== eventGroup
                )
              },
              () => {
                if (this.state.deletingEventGroups.length === 0)
                  this.loadEventGroups();
              }
            ),
          this.context
        );
      })
    );
  };

  renderEventGroup = (eventGroup, i) => {
    return (
      <Row key={eventGroup.id} className="border-bottom border-top">
        <Col xs="12" className="align-middle">
          <Checkbox
            checked={this.state.selectedEventGroups[i]}
            onClick={() => {
              const selected = [...this.state.selectedEventGroups];
              selected[i] = !selected[i];
              this.setState({ selectedEventGroups: selected });
            }}
            color="default"
          />
          <Link key={eventGroup.id} to={`/admin/event_group/${eventGroup.id}`}>
            <Button variant="link" className="text-left m-1 w-75">
              <div>{eventGroup.name}</div>
              <div className="text-justify">
                {truncateText(eventGroup.description, 200)}
              </div>
              {eventGroup.locked ? (
                <p className="text-danger">Událost je zamčena.</p>
              ) : (
                <></>
              )}
            </Button>
          </Link>
        </Col>
      </Row>
    );
  };

  renderEventGroups() {
    return this.state.eventGroups.map((eventGroup, i) =>
      this.renderEventGroup(eventGroup, i)
    );
  }

  render() {
    return (
      <>
        <Checkbox
          className="m-1"
          checked={this.state.selectedEventGroups.every(e => e === true)}
          onClick={this.handleMarkAll}
          indeterminate={
            this.state.selectedEventGroups.some(e => e === true) &&
            !this.state.selectedEventGroups.every(e => e === true)
          }
          color="default"
        />
        <ApiCallButton
          className="m-1 custom-lockbutton"
          variant="link"
          disabled={this.state.selectedEventGroups.every(e => e === false)}
          text={<FaLockImage className="basic-icon" />}
          textOnApiCall={<FaLockImage className="basic-icon" />}
          waiting={this.state.lockingEventGroups.length > 0}
          onClick={this.lockEventGroups}
          overlayText="Zamknout"
        />
        <ApiCallButton
          className="m-1 custom-lockbutton"
          variant="link"
          disabled={this.state.selectedEventGroups.every(e => e === false)}
          text={<FaUnlockImage className="basic-icon" />}
          textOnApiCall={<FaUnlockImage className="basic-icon" />}
          waiting={this.state.unlockingEventGroups.length > 0}
          onClick={this.unlockEventGroups}
          overlayText="Odemknout"
        />
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="deleteTooltip">Smazat</Tooltip>}
        >
          <ApiCallButtonWithModal
            className="m-1 custom-deletebutton"
            variant="link"
            disabled={this.state.selectedEventGroups.every(e => e === false)}
            text={<FaBinImage className="basic-icon" />}
            textOnApiCall={<FaBinImage className="basic-icon" />}
            waiting={this.state.deletingEventGroups.length > 0}
            onClick={this.deleteEventGroups}
            modalItemTypeLabel="Události"
            modalItemName={this.state.selectedEventGroups
              .map((el, i) => (el ? this.state.eventGroups[i].name : ""))
              .join("\n")}
            checkboxText={
              <div className={"px-2"}>
                <span className={"pr-2"}>Upozornit přihlášené?</span>
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="infoTooltip">
                      Emaily nebudou rozeslány, pro termíny událostí, které již
                      proběhly.
                    </Tooltip>
                  }
                >
                  <InfoCircleFill color="royalblue" />
                </OverlayTrigger>
              </div>
            }
          />
        </OverlayTrigger>
        <small>|</small>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="parentsViewTooltip">Pohled pro rodiče</Tooltip>}
        >
          <LinkContainer to={"/organization/" + this.props.organizationId}>
            <Button variant="link" className="m-1 custom-primarybutton-hover">
              <FaShareImage className="basic-icon" />
            </Button>
          </LinkContainer>
        </OverlayTrigger>
        <Dropdown className="d-inline">
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="newEventGroupTooltip">Nová událost</Tooltip>}
          >
            <Dropdown.Toggle
              variant="link"
              className="d-inline m-1 custom-primarybutton-hover custom-adddropdown"
            >
              <FaPlusImage className="basic-icon" />
            </Dropdown.Toggle>
          </OverlayTrigger>
          <Dropdown.Menu>
            <LinkContainer to="/admin/create/enrollment">
              <Dropdown.Item>Vytvořit zápis</Dropdown.Item>
            </LinkContainer>

            <LinkContainer to="/admin/create/course">
              <Dropdown.Item>Vytvořit kroužek</Dropdown.Item>
            </LinkContainer>
            <LinkContainer to="/admin/create/meeting">
              <Dropdown.Item>Vytvořit individuální schůzku</Dropdown.Item>
            </LinkContainer>
          </Dropdown.Menu>
        </Dropdown>
        {this.state.isLoading ? (
          <Spinner animation="grow" variant="primary" />
        ) : (
          this.renderEventGroups()
        )}
      </>
    );
  }
}

export default AdminListAllEventGroups;
