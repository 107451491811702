import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";

class StoryRow extends React.Component {
  render() {
    return (
      <Row
        className={`justify-content-center align-items-center pt-3 ${this.props.className}`}
      >
        {this.props.children}
      </Row>
    );
  }
}

class StoryCol extends React.Component {
  render() {
    return (
      <Col
        xs="12"
        sm="6"
        md="6"
        lg="6"
        xl="5"
        className={`m-1 ${this.props.className}`}
      >
        {this.props.children}
      </Col>
    );
  }
}

class StoryColImage extends React.Component {
  render() {
    return (
      <Col
        xs="12"
        sm="5"
        md="4"
        lg="4"
        xl="3"
        className={`m-1 ${this.props.className}`}
      >
        <img
          className="d-block w-50 m-auto p-3"
          src={this.props.src}
          alt="img"
        />
      </Col>
    );
  }
}

class SalesStory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true
    };
  }

  render() {
    return (
      <>
        <StoryRow className="pt-5">
          <Col xs="12" sm="4" md="5" lg="5" xl="4" className="float-left">
            <h1 className="float-left custom-flaticon-blue-color">BookIt</h1>
          </Col>
          <Col xs="12" sm="7" md="5" lg="5" xl="4" className="m-1">
            <LinkContainer key="testonly4" to="/admin">
              <Button
                variant="success"
                className="m-1 float-right custom-button-rounded-corners"
              >
                Administrace
              </Button>
            </LinkContainer>
            <Button
              variant="primary"
              href="#tryit"
              className="m-1 float-right custom-flaticon-blue-background-color custom-button-rounded-corners"
            >
              Vytvořit účet zdarma
            </Button>
          </Col>
        </StoryRow>

        <StoryRow className="pt-4">
          <Col xs="12" sm="6" md="5" lg="5" xl="4" className="m-1 order-1">
            <h2>S námi je organizace událostí hračka</h2>
            <p>
              Pořádání událostí může být velmi stresující. Každý účastník může
              přijít v jiný čas a někdy se jich sejde hodně najednou. Jak se
              tomuto vyhnout? Nabízíme Vám řešení.{" "}
            </p>
            <LinkContainer
              key="testonly3"
              to="/organization/2de640ba-7fe8-4260-bce2-39bc8f103778"
            >
              <Button
                variant="primary"
                className="m-1 custom-flaticon-blue-background-color custom-button-rounded-corners"
              >
                Vyzkoušet aplikaci
              </Button>
            </LinkContainer>
            <a key="manual" href="/api/static/manual.pdf">
              <Button
                variant="success"
                className="m-1 custom-button-rounded-corners"
              >
                Příručka k aplikaci
              </Button>
            </a>
          </Col>
          <Col xs="12" sm="5" md="5" lg="5" xl="4" className="m-1 order-2">
            <img
              className="d-block w-50 m-auto p-3"
              src="/027-right.png"
              alt="img"
            />
          </Col>
        </StoryRow>

        <hr className="hr-wide" />
        <StoryRow>
          <h2>Co vše naše aplikace umí?</h2>
        </StoryRow>
        <StoryRow>
          <StoryCol className="order-sm-2 order-xs-1">
            <h3>Zápis do prvních tříd</h3>
            <p>
              Zápis budoucích prvňáčků může být poměrně chaotická událost. Více
              rodičů může přijít s dítětem ve stejný čas a potom zbytečně stát
              ve frontě. S naší aplikací se rodiče přihlásí na konkrétní termín
              rychle a jednoduše.
            </p>
          </StoryCol>
          <StoryColImage
            className="order-sm-1 order-xs-2"
            src="/020-write.png"
          />
        </StoryRow>
        <StoryRow>
          <StoryCol className="order-1">
            <h3>Individuální třídní schůzky</h3>
            <p>
              Při individuálních třídních schůzkách se učitel může věnovat pouze
              jednomu rodiči. Je proto důležité najít čas, který bude vyhovovat
              učiteli i rodiči. Dosáhněte toho snadno s naší aplikací.
            </p>
          </StoryCol>
          <StoryColImage className="order-2" src="/049-learning-1.png" />
        </StoryRow>
        <StoryRow>
          <StoryCol className="order-sm-2 order-xs-1">
            <h3>Zápis do zájmových kroužků</h3>
            <p>
              Zájmové kroužky mají limitovanou kapacitu a je možné je vyučovat
              jen v určitém čase. S naší aplikací přihlásí rodiče své děti do
              kroužků rychle a jednoduše.
            </p>
          </StoryCol>
          <StoryColImage
            className="order-sm-1 order-xs-2"
            src="/040-music.png"
          />
        </StoryRow>

        <hr className="hr-wide" />
        <StoryRow>
          <h2>Jak to funguje?</h2>
        </StoryRow>
        <StoryRow>
          <StoryCol className="order-sm-2 order-xs-1">
            <h3>Snadno vypíšete termín události</h3>
            <p>
              Jednoduchým způsobem vytvoříte událost s termíny pro zápis do
              prvních tříd přesně tak, jak Vám to bude vyhovovat.
            </p>
          </StoryCol>
          <StoryColImage
            className="order-sm-1 order-xs-2"
            src="/083-timetable.png"
          />
        </StoryRow>
        <StoryRow>
          <StoryCol className="order-sm-2 order-xs-1">
            <h3>Odkaz zveřejníte na stránkách školy</h3>
            <p>
              Odkaz na událost zveřejníte na stránkách své školy. Z Vaší strany
              není nutná další koordinace, protože o vše ostatní se postará naše
              aplikace.
            </p>
          </StoryCol>
          <StoryColImage
            className="order-sm-1 order-xs-2"
            src="/005-online-education-2.png"
          />
        </StoryRow>
        <StoryRow>
          <StoryCol className="order-sm-2 order-xs-1">
            <h3>Rodiče se přihlásí na volné termíny</h3>
            <p>
              Rodiče se přihlásí na volné termíny, které jim vyhovují.
              Přihlášení je možné provést z počítače i mobilního telefonu.
              Rodiče emailem dostanou potvrzení s termínem události a odkazem
              pro jeho případné zrušení.
            </p>
          </StoryCol>
          <StoryColImage
            className="order-sm-1 order-xs-2"
            src="/058-students-1.png"
          />
        </StoryRow>

        <StoryRow>
          <StoryCol className="order-sm-2 order-xs-1">
            <h3>S rodiči se můžete setkat ve vytvořeném videohovoru</h3>
            <p>
              Pro případy, kdy je třeba se s rodiči setkat bezkontaktně, pro Vás
              vytvoříme videhovor. Přihlášený rodič obdrží unikátní odkaz na
              videohovor spolu s potvrzením registrace, a tak se snadno v čase
              události sejdete ve stejné video místnosti.
            </p>
          </StoryCol>
          <StoryColImage
            className="order-sm-1 order-xs-2"
            src="/046-seminar.png"
          />
        </StoryRow>

        <StoryRow>
          <StoryCol className="order-sm-2 order-xs-1">
            <h3>Získáte přehled o všech přihlášených</h3>
            <p>
              Vždy budete mít k dispozici aktuální seznam přihlášených rodičů.
            </p>
          </StoryCol>
          <StoryColImage
            className="order-sm-1 order-xs-2"
            src="/013-profile.png"
          />
        </StoryRow>
      </>
    );
  }
}

export default SalesStory;
