import React from "react";
import Button from "react-bootstrap/Button";
import { AlertBarContext } from "../AlertBarContextProvider";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import { getEventGroups } from "../Api/EventGroup";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

class ListAllEventsGroups extends React.Component {
  static contextType = AlertBarContext;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      eventGroups: [],
      meetings: [],
      courses: [],
      enrollments: [],
      others: []
    };
  }

  componentDidMount() {
    this.loadEventGroups();
  }

  storeLoadedEventGroups = eventGroupsData => {
    const eventGroups = eventGroupsData.map(eventGroup => {
      eventGroup.enableButton = true;
      return eventGroup;
    });
    const meetings = eventGroups.filter(
      eventGroup => eventGroup.event_group_type === "meeting"
    );
    const courses = eventGroups.filter(
      eventGroup => eventGroup.event_group_type === "course"
    );
    const enrollments = eventGroups.filter(
      eventGroup => eventGroup.event_group_type === "enrollment"
    );
    const others = eventGroups.filter(
      eventGroup =>
        eventGroup.event_group_type !== "enrollment" &&
        eventGroup.event_group_type !== "course" &&
        eventGroup.event_group_type !== "meeting"
    );
    this.setState({
      eventGroups: eventGroups,
      meetings: meetings,
      courses: courses,
      enrollments: enrollments,
      others: others
    });
  };

  loadEventGroups = () => {
    getEventGroups(
      this.props.organizationId,
      () => this.setState({ isLoading: true }),
      res => this.storeLoadedEventGroups(res.data),
      () => {},
      () => this.setState({ isLoading: false }),
      this.context
    );
  };

  renderEventGroup = eventGroup => {
    let variant = "primary";
    let isDisabled = false;
    let linkTo = "/events/" + eventGroup.id;
    if (eventGroup.locked) {
      variant = "warning";
      isDisabled = true;
      linkTo = "#";
    }
    return (
      <Link
        key={eventGroup.id}
        to={linkTo}
        className="col-xl-3 col-lg-3 col-md-4 col-sm-5 col-xs-12 m-1"
      >
        <Button
          variant={variant}
          disabled={isDisabled}
          className="m-1 pt-4 pb-4 text-center align-middle h-100 w-100"
        >
          {eventGroup.name}
          {eventGroup.locked ? (
            <div>
              <small>Uzavřeno</small>
            </div>
          ) : (
            <></>
          )}
          {/*eventGroup.description*/}
        </Button>
      </Link>
    );
  };

  renderMeetingEventGroupsYear = year => {
    const egs = this.state.meetings.filter(meeting => {
      return meeting.name.charAt(0) === year;
    });
    return egs.map(meeting => this.renderEventGroup(meeting));
  };

  renderMeetingEventGroups() {
    if (this.state.meetings.length > 0) {
      const years = Array.from(
        new Set(this.state.meetings.map(meeting => meeting.name.charAt(0)))
      );
      return (
        <Tab eventKey="meeting" title="Schůzky">
          {years.map(year => (
            <Row key={year}>
              <Col xs="12">
                <legend className="mt-4 mb-1">{year}. ročník</legend>
              </Col>
              {this.renderMeetingEventGroupsYear(year)}
            </Row>
          ))}
        </Tab>
      );
    }
  }

  renderEnrollmentEventGroups() {
    if (this.state.enrollments.length > 0) {
      return (
        <Tab eventKey="enrollment" title="Zápisy">
          <Row key="enrollments">
            <Col xs="12">
              <legend className="mt-4 mb-1">Aktivní zápisy</legend>
            </Col>
            {this.state.enrollments.map(enrollment =>
              this.renderEventGroup(enrollment)
            )}
          </Row>
        </Tab>
      );
    }
  }

  renderCourseEventGroups() {
    if (this.state.courses.length > 0) {
      return (
        <Tab eventKey="course" title="Kroužky">
          <Row key="courses">
            <Col xs="12">
              <legend className="mt-4 mb-1">Aktivní kroužky</legend>
            </Col>
            {this.state.courses.map(course => this.renderEventGroup(course))}
          </Row>
        </Tab>
      );
    }
  }

  renderAll() {
    if (
      this.state.courses.length > 0 ||
      this.state.enrollments.length > 0 ||
      this.state.meetings.length > 0
    ) {
      return (
        <Tabs>
          {this.renderEnrollmentEventGroups()}
          {this.renderMeetingEventGroups()}
          {this.renderCourseEventGroups()}
        </Tabs>
      );
    } else {
      return <div>Škola nemá vypsané žádné události.</div>;
    }
  }

  render() {
    return this.state.isLoading ? (
      <Spinner animation="grow" variant="primary" />
    ) : (
      this.renderAll()
    );
  }
}

export default ListAllEventsGroups;
