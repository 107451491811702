import ReactGA from "react-ga";

export function getEventGroup(
  eventGroupId,
  pre_api_callback,
  then_callback,
  error_callback,
  finally_callback,
  context
) {
  pre_api_callback();
  global.api
    .get("/api/v1/user/event_group/" + eventGroupId)
    .then(res => then_callback(res))
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        ReactGA.event({
          category: "Error",
          action: "EventGroup",
          label: error.response.toString()
        });
        switch (error.response.status) {
          case 404:
            context.addMessage(
              "danger",
              "Při načítání nastala chyba. Událost nebyla nalezena."
            );
            break;
          default:
            context.addMessage(
              "danger",
              "Při načítání nastala chyba. Zkuste to prosím znovu."
            );
        }
      } else if (error.request) {
        ReactGA.event({
          category: "Error",
          action: "EventGroup",
          label: error.request.toString()
        });
        console.log(error.request);
      } else {
        ReactGA.event({
          category: "Error",
          action: "EventGroup",
          label: error.message
        });
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      error_callback(error);
    })
    .finally(() => finally_callback());
}

export function getEventGroupAdmin(
  eventGroupId,
  pre_api_callback,
  then_callback,
  error_callback,
  finally_callback,
  context
) {
  pre_api_callback();
  global.api
    .get("/api/v1/admin/event_group/" + eventGroupId)
    .then(res => then_callback(res))
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        ReactGA.event({
          category: "Error",
          action: "EventGroup",
          label: error.response.toString()
        });
        switch (error.response.status) {
          case 401:
            context.addMessage(
              "danger",
              "Vaše přihlášení vypršelo. Přihlaste se prosím znovu."
            );
            context.signOut();
            break;
          case 403:
            context.addMessage(
              "danger",
              "Při načítání nastala chyba. Nemáte dostatečná oprávnění."
            );
            break;
          case 404:
            context.addMessage(
              "danger",
              "Při načítání nastala chyba. Událost nebyla nalezena."
            );
            break;
          default:
            context.addMessage(
              "danger",
              "Při načítání nastala chyba. Zkuste to prosím znovu."
            );
        }
      } else if (error.request) {
        ReactGA.event({
          category: "Error",
          action: "EventGroup",
          label: error.request.toString()
        });
        console.log(error.request);
      } else {
        ReactGA.event({
          category: "Error",
          action: "EventGroup",
          label: error.message
        });
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      error_callback(error);
    })
    .finally(() => finally_callback());
}

export function getEventGroups(
  organizationId,
  pre_api_callback,
  then_callback,
  error_callback,
  finally_callback,
  context
) {
  pre_api_callback();
  global.api
    .get("/api/v1/user/event_groups/" + organizationId)
    .then(res => then_callback(res))
    .catch(error => {
      ReactGA.event({
        category: "Error",
        action: "EventGroup",
        label: error.response.toString()
      });
      if (error.response) {
        console.log(error.response);
        // only one possible error
        context.addMessage(
          "danger",
          "Při načítání nastala chyba. Zkuste to prosím znovu."
        );
      } else if (error.request) {
        ReactGA.event({
          category: "Error",
          action: "EventGroup",
          label: error.request.toString()
        });
        console.log(error.request);
      } else {
        ReactGA.event({
          category: "Error",
          action: "EventGroup",
          label: error.message
        });
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      error_callback(error);
    })
    .finally(() => finally_callback());
}

export function getEventGroupsAdmin(
  pre_api_callback,
  then_callback,
  error_callback,
  finally_callback,
  context
) {
  pre_api_callback();
  global.api
    .get("/api/v1/admin/event_groups")
    .then(res => then_callback(res))
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        ReactGA.event({
          category: "Error",
          action: "EventGroup",
          label: error.response.toString()
        });
        switch (error.response.status) {
          case 401:
            context.addMessage(
              "danger",
              "Vaše přihlášení vypršelo. Přihlaste se prosím znovu."
            );
            context.signOut();
            break;
          default:
            context.addMessage(
              "danger",
              "Při načítání nastala chyba. Zkuste to prosím znovu."
            );
        }
      } else if (error.request) {
        ReactGA.event({
          category: "Error",
          action: "EventGroup",
          label: error.request.toString()
        });
        console.log(error.request);
      } else {
        ReactGA.event({
          category: "Error",
          action: "EventGroup",
          label: error.message
        });
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      error_callback(error);
    })
    .finally(() => finally_callback());
}

export function createEventGroup(
  name,
  duration,
  description,
  eventGrouptype,
  defaultCapacity,
  videoCall,
  pre_api_callback,
  then_callback,
  error_callback,
  finally_callback,
  context
) {
  pre_api_callback();
  const data = {
    event_group: {
      name: name,
      duration: duration,
      description: description,
      event_group_type: eventGrouptype,
      default_capacity: defaultCapacity,
      video_call: videoCall
    },
    events: []
  };
  global.api
    .post(`/api/v1/admin/event_group`, data)
    .then(res => {
      then_callback(res);
      ReactGA.event({ category: "EventGroup", action: "Create" });
    })
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        ReactGA.event({
          category: "Error",
          action: "EventGroup",
          label: error.response.toString()
        });
        switch (error.response.status) {
          case 401:
            context.addMessage(
              "danger",
              "Vaše přihlášení vypršelo. Přihlaste se prosím znovu."
            );
            context.signOut();
            break;
          default:
            context.addMessage(
              "danger",
              "Při vytváření události nastala chyba. Zkuste to prosím znovu."
            );
        }
      } else if (error.request) {
        ReactGA.event({
          category: "Error",
          action: "EventGroup",
          label: error.request.toString()
        });
        console.log(error.request);
      } else {
        ReactGA.event({
          category: "Error",
          action: "EventGroup",
          label: error.message
        });
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      error_callback(error);
    })
    .finally(() => finally_callback());
}

export function unlockEventGroup(
  eventGroupId,
  pre_api_callback,
  then_callback,
  error_callback,
  finally_callback,
  context
) {
  pre_api_callback();
  global.api
    .post("/api/v1/admin/event_group/" + eventGroupId + "/unlock")
    .then(res => {
      then_callback(res);
      ReactGA.event({
        category: "EventGroup",
        action: "Modify",
        label: "Unlock"
      });
    })
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        ReactGA.event({
          category: "Error",
          action: "EventGroup",
          label: error.response.toString()
        });
        switch (error.response.status) {
          case 401:
            context.addMessage(
              "danger",
              "Vaše přihlášení vypršelo. Přihlaste se prosím znovu."
            );
            context.signOut();
            break;
          case 403:
            context.addMessage(
              "danger",
              "Při odemykání události nastala chyba. Nemáte dostatečná oprávnění."
            );
            break;
          case 404:
            context.addMessage(
              "danger",
              "Při odemykání události nastala chyba. Událost nebyla nalezena."
            );
            break;
          default:
            context.addMessage(
              "danger",
              "Při odemykání události nastala chyba. Zkuste to prosím znovu."
            );
        }
      } else if (error.request) {
        ReactGA.event({
          category: "Error",
          action: "EventGroup",
          label: error.request.toString()
        });
        console.log(error.request);
      } else {
        ReactGA.event({
          category: "Error",
          action: "EventGroup",
          label: error.message
        });
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      error_callback(error);
    })
    .finally(() => finally_callback());
}

export function lockEventGroup(
  eventGroupId,
  pre_api_callback,
  then_callback,
  error_callback,
  finally_callback,
  context
) {
  pre_api_callback();
  global.api
    .post("/api/v1/admin/event_group/" + eventGroupId + "/lock")
    .then(res => {
      then_callback(res);
      ReactGA.event({
        category: "EventGroup",
        action: "Modify",
        label: "Lock"
      });
    })
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        ReactGA.event({
          category: "Error",
          action: "EventGroup",
          label: error.response.toString()
        });
        switch (error.response.status) {
          case 401:
            context.addMessage(
              "danger",
              "Vaše přihlášení vypršelo. Přihlaste se prosím znovu."
            );
            context.signOut();
            break;
          case 403:
            context.addMessage(
              "danger",
              "Při zamykání události nastala chyba. Nemáte dostatečná oprávnění."
            );
            break;
          case 404:
            context.addMessage(
              "danger",
              "Při zamykání události nastala chyba. Událost nebyla nalezena."
            );
            break;
          default:
            context.addMessage(
              "danger",
              "Při zamykání události nastala chyba. Zkuste to prosím znovu."
            );
        }
      } else if (error.request) {
        ReactGA.event({
          category: "Error",
          action: "EventGroup",
          label: error.request.toString()
        });
        console.log(error.request);
      } else {
        ReactGA.event({
          category: "Error",
          action: "EventGroup",
          label: error.message
        });
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      error_callback(error);
    })
    .finally(() => finally_callback());
}

export function deleteEventGroup(
  eventGroupId,
  notify = false,
  pre_api_callback,
  then_callback,
  error_callback,
  finally_callback,
  context
) {
  pre_api_callback();
  global.api
    .delete("/api/v1/admin/event_group/" + eventGroupId, {
      data: {
        notify_subscriber: notify
      }
    })
    .then(res => {
      then_callback(res);
      ReactGA.event({ category: "EventGroup", action: "Delete" });
    })
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        ReactGA.event({
          category: "Error",
          action: "EventGroup",
          label: error.response.toString()
        });
        switch (error.response.status) {
          case 401:
            context.addMessage(
              "danger",
              "Vaše přihlášení vypršelo. Přihlaste se prosím znovu."
            );
            context.signOut();
            break;
          case 403:
            context.addMessage(
              "danger",
              "Při mazání události nastala chyba. Nemáte dostatečná oprávnění."
            );
            break;
          case 404:
            context.addMessage(
              "danger",
              "Při mazání události nastala chyba. Událost nebyla nalezena."
            );
            break;
          default:
            context.addMessage(
              "danger",
              "Při mazání události nastala chyba. Zkuste to prosím znovu."
            );
        }
      } else if (error.request) {
        ReactGA.event({
          category: "Error",
          action: "EventGroup",
          label: error.request
        });
        console.log(error.request);
      } else {
        ReactGA.event({
          category: "Error",
          action: "EventGroup",
          label: error.message
        });
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      error_callback(error);
    })
    .finally(() => finally_callback());
}

export function modifyEventGroup(
  eventGroupId,
  name,
  description,
  pre_api_callback,
  then_callback,
  error_callback,
  finally_callback,
  context
) {
  pre_api_callback();
  const data = Object.assign(
    {},
    name === null ? null : { name },
    description === null ? null : { description }
  );
  global.api
    .post("/api/v1/admin/event_group/" + eventGroupId, data)
    .then(res => {
      then_callback(res);
      ReactGA.event({
        category: "EventGroup",
        action: "Modify",
        label: "Change name/description"
      });
    })
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        ReactGA.event({
          category: "Error",
          action: "EventGroup",
          label: error.response.toString()
        });
        switch (error.response.status) {
          case 401:
            context.addMessage(
              "danger",
              "Vaše přihlášení vypršelo. Přihlaste se prosím znovu."
            );
            context.signOut();
            break;
          case 403:
            context.addMessage(
              "danger",
              "Při změně události nastala chyba. Nemáte dostatečná oprávnění."
            );
            break;
          case 404:
            context.addMessage(
              "danger",
              "Při změně události nastala chyba. Událost nebyla nalezena."
            );
            break;
          default:
            context.addMessage(
              "danger",
              "Při změně události nastala chyba. Zkuste to prosím znovu."
            );
        }
      } else if (error.request) {
        ReactGA.event({
          category: "Error",
          action: "EventGroup",
          label: error.request
        });
        console.log(error.request);
      } else {
        ReactGA.event({
          category: "Error",
          action: "EventGroup",
          label: error.message
        });
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      error_callback(error);
    })
    .finally(() => finally_callback());
}
