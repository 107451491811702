import React from "react";
import { AlertBarContext } from "../../AlertBarContextProvider";
import Button from "react-bootstrap/Button";
import { FaPlusImage, FaCancelImage } from "../Icons";
import Form from "react-bootstrap/esm/Form";
import Col from "react-bootstrap/Col";
import { DatePicker, TimePicker } from "@material-ui/pickers";
import ApiCallButton from "../ApiCallButton";
import EventTimesTable from "../EventTimesTable";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { createEventsInEventGroup } from "../../Api/Event";

function dateToTimestamp(date) {
  return parseInt((new Date(date).getTime() / 1000).toFixed(0));
}

class AddEvent extends React.Component {
  static contextType = AlertBarContext;

  constructor(props) {
    super(props);

    let startD = new Date();
    startD.setHours(8, 0, 0, 0);

    let stopD = new Date();
    stopD.setHours(17, 0, 0, 0);

    this.state = {
      startDate: startD,
      stopDate: stopD,
      hide: true,
      addingEvents: false,
      capacities: [],
      eventsTimes: [],
      selected: []
    };
  }

  update_events = () => {
    const events = [];
    let timestamp = dateToTimestamp(this.state.startDate);
    while (
      timestamp + this.props.duration * 60 <=
      dateToTimestamp(this.state.stopDate)
    ) {
      events.push(timestamp);
      timestamp += this.props.duration * 60;
    }
    this.setState({
      eventsTimes: events,
      selected: Array.from(events, () => true), // clear all custom settings
      capacities: Array.from(events, () => this.props.defaultCapacity) // clear all custom settings
    });
  };

  handleEventSelected = i => {
    const val = [...this.state.selected];
    val[i] = !val[i];
    this.setState({ selected: val });
  };
  handleCapacityDecrease = i => {
    const capacities_copy = [...this.state.capacities];
    capacities_copy[i] = Math.max(1, parseInt(this.state.capacities[i]) - 1);
    this.setState({ capacities: capacities_copy });
  };

  handleCapacityIncrease = i => {
    const capacities_copy = [...this.state.capacities];
    capacities_copy[i] = parseInt(this.state.capacities[i]) + 1;
    this.setState({ capacities: capacities_copy });
  };

  startTimeChange = date => {
    const d = new Date(
      this.state.startDate.getFullYear(),
      this.state.startDate.getMonth(),
      this.state.startDate.getDate(),
      date.getHours(),
      date.getMinutes()
    );
    this.setState({ startDate: d }, () => this.update_events());
  };

  stopTimeChange = date => {
    const d = new Date(
      this.state.stopDate.getFullYear(),
      this.state.stopDate.getMonth(),
      this.state.stopDate.getDate(),
      date.getHours(),
      date.getMinutes()
    );
    this.setState({ stopDate: d }, () => this.update_events());
  };

  handleDateChange = newDate => {
    const newStartDate = new Date(
      newDate.getFullYear(),
      newDate.getMonth(),
      newDate.getDate(),
      this.state.startDate.getHours(),
      this.state.startDate.getMinutes()
    );
    const newStopDate = new Date(
      newDate.getFullYear(),
      newDate.getMonth(),
      newDate.getDate(),
      this.state.stopDate.getHours(),
      this.state.stopDate.getMinutes()
    );
    this.setState({ startDate: newStartDate, stopDate: newStopDate }, () =>
      this.update_events()
    );
  };

  onEventsAdd = () => {
    const events = this.state.selected
      .map((el, i) =>
        el === true
          ? {
              start: this.state.eventsTimes[i],
              end: this.state.eventsTimes[i] + this.props.duration * 60,
              capacity: this.state.capacities[i]
            }
          : null
      )
      .filter(el => el !== null);

    createEventsInEventGroup(
      this.props.eventGroupId,
      events,
      () => this.setState({ addingEvents: true }),
      () => {
        this.props.onEventsAdded();
        this.context.addMessage(
          "success",

          "Bylo přidáno " + events.length + " nových termínů."
        );
      },
      () => {},
      () => this.setState({ addingEvents: false, hide: true }),
      this.context
    );
  };

  render() {
    return this.state.hide ? (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="addEventTooltip">Přidat termín</Tooltip>}
      >
        <Button
          variant="link"
          className="mt-2 mr-2 custom-addbutton-hover"
          onClick={() => {
            this.update_events();
            this.setState({ hide: false });
          }}
        >
          <FaPlusImage className="basic-icon" />
        </Button>
      </OverlayTrigger>
    ) : (
      <>
        <hr />
        <Button
          className="float-right custom-deletebutton-hover"
          variant="link"
          onClick={() => this.setState({ hide: true })}
        >
          <FaCancelImage className="basic-icon" />
        </Button>
        <Form className="text-center">
          <label className="border-bottom">Přidat termín</label>
          <Form.Group controlId="datum" className="w-100 text-center">
            <Form.Row className="w-100 text-center">
              <Col>
                <DatePicker
                  autoOk
                  disablePast
                  label="Datum"
                  value={this.state.startDate}
                  onChange={this.handleDateChange}
                  minutesStep={5}
                  format="dd. MM. yyyy"
                  cancelLabel="Zrušit"
                />
              </Col>
              <Col>
                <TimePicker
                  autoOk
                  disablePast
                  ampm={false}
                  label="Od"
                  value={this.state.startDate}
                  onChange={this.startTimeChange}
                  minutesStep={5}
                  format="HH:mm"
                  cancelLabel="Zrušit"
                />
              </Col>
              <Col>
                <TimePicker
                  autoOk
                  disablePast
                  ampm={false}
                  label="Do"
                  value={this.state.stopDate}
                  onChange={this.stopTimeChange}
                  minutesStep={5}
                  format="HH:mm"
                  cancelLabel="Zrušit"
                />
              </Col>
            </Form.Row>
          </Form.Group>

          <EventTimesTable
            eventsTimes={this.state.eventsTimes}
            selected={this.state.selected}
            duration={this.props.duration * 60}
            capacities={this.state.capacities}
            defaultCapacity={this.props.defaultCapacity}
            onEventTimeClick={this.handleEventSelected}
            onCapacityIncrease={this.handleCapacityIncrease}
            onCapacityDecrease={this.handleCapacityDecrease}
          />

          <ApiCallButton
            onClick={this.onEventsAdd}
            className="mt-2"
            text="Přidat termíny"
            textOnApiCall="Přidávám termíny"
            waiting={this.state.addingEvents}
          />
        </Form>
      </>
    );
  }
}

export default AddEvent;
