import ReactGA from "react-ga";

export function getEventsByEventGroup(
  eventGroupId,
  pre_api_callback,
  then_callback,
  error_callback,
  finally_callback,
  context
) {
  pre_api_callback();
  global.api
    .get("/api/v1/user/events/" + eventGroupId)
    .then(res => then_callback(res))
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        ReactGA.event({
          category: "Error",
          action: "Event",
          label: error.response.toString()
        });
        switch (error.response.status) {
          case 404:
            context.addMessage(
              "danger",
              "Při načítání termínů události nastala chyba. Událost nebyla nalezena."
            );
            break;
          default:
            context.addMessage(
              "danger",
              "Při načítání termínů události nastala chyba. Zkuste to prosím znovu."
            );
        }
      } else if (error.request) {
        ReactGA.event({
          category: "Error",
          action: "Event",
          label: error.request.toString()
        });
        console.log(error.request);
      } else {
        ReactGA.event({
          category: "Error",
          action: "Event",
          label: error.message
        });
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      error_callback(error);
    })
    .finally(() => finally_callback());
}

export function createEventsInEventGroup(
  eventGroupId,
  events,
  pre_api_callback,
  then_callback,
  error_callback,
  finally_callback,
  context
) {
  pre_api_callback();
  global.api
    .post(
      "/api/v1/admin/event_group/" + eventGroupId + "/create_events",
      events
    )
    .then(res => {
      then_callback(res);
      ReactGA.event({ category: "Event", action: "Create" });
    })
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        ReactGA.event({
          category: "Error",
          action: "Event",
          label: error.response.toString()
        });
        switch (error.response.status) {
          case 401:
            context.addMessage(
              "danger",
              "Vaše přihlášení vypršelo. Přihlaste se prosím znovu."
            );
            context.signOut();
            break;
          case 403:
            context.addMessage(
              "danger",
              "Pro přidávání termínu události nemáte dostatečná oprávnění."
            );
            break;
          case 404:
            context.addMessage(
              "danger",
              "Při přidávání termínu události nastala chyba. Událost nebyla nalezena."
            );
            break;
          default:
            context.addMessage(
              "danger",
              "Při přidávání termínu události nastala chyba. Zkuste to prosím znovu."
            );
        }
      } else if (error.request) {
        ReactGA.event({
          category: "Error",
          action: "Event",
          label: error.request.toString()
        });
        console.log(error.request);
      } else {
        ReactGA.event({
          category: "Error",
          action: "Event",
          label: error.message
        });
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      error_callback(error);
    })
    .finally(() => finally_callback());
}

export function incrementCapacity(
  eventId,
  pre_api_callback,
  then_callback,
  error_callback,
  finally_callback,
  context
) {
  pre_api_callback();
  global.api
    .post("/api/v1/admin/event/" + eventId + "/capacity_increment")
    .then(res => {
      then_callback(res);
      ReactGA.event({
        category: "Event",
        action: "Modify",
        label: "Increment capacity"
      });
    })
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        ReactGA.event({
          category: "Error",
          action: "Event",
          label: error.response.toString()
        });
        switch (error.response.status) {
          case 401:
            context.addMessage(
              "danger",
              "Vaše přihlášení vypršelo. Přihlaste se prosím znovu."
            );
            context.signOut();
            break;
          case 403:
            context.addMessage(
              "danger",
              "Pro úpravu události nemáte dostatečná oprávnění."
            );
            break;
          case 404:
            context.addMessage(
              "danger",
              "Při navyšování kapacity události nastala chyba. Událost nebyla nalezena."
            );
            break;
          default:
            context.addMessage(
              "danger",
              "Při navyšování kapacity události nastala chyba. Zkuste to prosím znovu."
            );
        }
      } else if (error.request) {
        ReactGA.event({
          category: "Error",
          action: "Event",
          label: error.request.toString()
        });
        console.log(error.request);
      } else {
        ReactGA.event({
          category: "Error",
          action: "Event",
          label: error.message
        });
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      error_callback(error);
    })
    .finally(() => finally_callback());
}

export function decrementCapacity(
  eventId,
  pre_api_callback,
  then_callback,
  error_callback,
  finally_callback,
  context
) {
  pre_api_callback();
  global.api
    .post("/api/v1/admin/event/" + eventId + "/capacity_decrement")
    .then(res => {
      then_callback(res);
      ReactGA.event({
        category: "Event",
        action: "Modify",
        label: "Decrement capacity"
      });
    })
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        ReactGA.event({
          category: "Error",
          action: "Event",
          label: error.response.toString()
        });
        switch (error.response.status) {
          case 401:
            context.addMessage(
              "danger",
              "Vaše přihlášení vypršelo. Přihlaste se prosím znovu."
            );
            context.signOut();
            break;
          case 403:
            context.addMessage(
              "danger",
              "Pro úpravu události nemáte dostatečná oprávnění."
            );
            break;
          case 404:
            context.addMessage(
              "danger",
              "Při snižování kapacity události nastala chyba. Událost nebyla nalezena."
            );
            break;
          default:
            context.addMessage(
              "danger",
              "Při snižování kapacity události nastala chyba. Zkuste to prosím znovu."
            );
        }
      } else if (error.request) {
        ReactGA.event({
          category: "Error",
          action: "Event",
          label: error.request.toString()
        });
        console.log(error.request);
      } else {
        ReactGA.event({
          category: "Error",
          action: "Event",
          label: error.message
        });
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      error_callback(error);
    })
    .finally(() => finally_callback());
}

export function deleteEvent(
  eventId,
  notify = false,
  pre_api_callback,
  then_callback,
  error_callback,
  finally_callback,
  context
) {
  pre_api_callback();
  global.api
    .delete("/api/v1/admin/event/" + eventId, {
      data: {
        notify_subscriber: notify
      }
    })
    .then(res => {
      then_callback(res);
      ReactGA.event({ category: "Event", action: "Delete" });
    })
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        ReactGA.event({
          category: "Error",
          action: "Event",
          label: error.response.toString()
        });
        switch (error.response.status) {
          case 401:
            context.addMessage(
              "danger",
              "Vaše přihlášení vypršelo. Přihlaste se prosím znovu."
            );
            context.signOut();
            break;
          case 403:
            context.addMessage(
              "danger",
              "Pro zrušení události nemáte dostatečná oprávnění."
            );
            break;
          case 404:
            context.addMessage(
              "danger",
              "Při rušení události nastala chyba. Událost nebyla nalezena."
            );
            break;
          default:
            context.addMessage(
              "danger",
              "Při rušení události nastala chyba. Zkuste to prosím znovu."
            );
        }
      } else if (error.request) {
        ReactGA.event({
          category: "Error",
          action: "Event",
          label: error.request.toString()
        });
        console.log(error.request);
      } else {
        ReactGA.event({
          category: "Error",
          action: "Event",
          label: error.message
        });
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      error_callback(error);
    })
    .finally(() => finally_callback());
}
