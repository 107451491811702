import ReactGA from "react-ga";

export function contactForm(
  email,
  phone,
  pre_api_callback,
  then_callback,
  error_callback,
  finally_callback,
  context
) {
  pre_api_callback();
  global.api
    .post("/api/v1/contact_form", { email: email, phone: phone })
    .then(res => then_callback(res))
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        ReactGA.event({
          category: "Error",
          action: "ContactForm",
          label: error.response.toString()
        });
        context.addMessage(
          "danger",
          "Při odesílání formuláře nastala chyba. Zkuste to prosím znovu."
        );
      } else if (error.request) {
        ReactGA.event({
          category: "Error",
          action: "ContactForm",
          label: error.request.toString()
        });
        console.log(error.request);
      } else {
        ReactGA.event({
          category: "Error",
          action: "ContactForm",
          label: error.message
        });
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      error_callback(error);
    })
    .finally(() => finally_callback());
}
