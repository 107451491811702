import Button from "react-bootstrap/Button";
import React from "react";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import Checkbox from "@material-ui/core/Checkbox";

class ApiCallButtonWithModal extends React.Component {
  /*
    props:
    * waiting
    * text
    * textOnApiCall
    * modalItemTypeLabel
    * modalItemName
    * checkboxText
    */

  constructor(props) {
    super(props);

    this.state = {
      modalShow: false,
      waitForAction: false,
      checkBoxChecked: false
    };
  }

  render() {
    const toforwardprops = Object.assign({}, this.props);

    delete toforwardprops.waiting;
    delete toforwardprops.textOnApiCall;
    delete toforwardprops.text;
    delete toforwardprops.onClick;
    delete toforwardprops.modalItemTypeLabel;
    delete toforwardprops.modalItemName;
    if (this.state.waitForAction === true && this.props.waiting === false) {
      this.setState({ modalShow: false, waitForAction: false });
    }
    return (
      <>
        <Button
          {...toforwardprops}
          disabled={this.props.waiting || this.props.disabled}
          onClick={() => this.setState({ modalShow: true })}
        >
          {this.props.text}
        </Button>
        <Modal
          show={this.state.modalShow || this.state.waitForAction}
          onHide={() => this.setState({ modalShow: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Smazat {this.props.modalItemTypeLabel}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Opravdu si přejete smazat{" "}
            {this.props.modalItemTypeLabel.toLowerCase()}?
            <br />
            <b>{this.props.modalItemName}</b>
          </Modal.Body>
          <Modal.Footer>
            {this.props.checkboxText !== undefined ? (
              <>
                <p>{this.props.checkboxText}</p>
                <Checkbox
                  className="m-1"
                  checked={this.state.checkBoxChecked}
                  onClick={() =>
                    this.setState({
                      checkBoxChecked: !this.state.checkBoxChecked
                    })
                  }
                  color="default"
                />
              </>
            ) : (
              <></>
            )}
            <Button
              variant="secondary"
              onClick={() => this.setState({ modalShow: false })}
            >
              Ne
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                this.setState({ waitForAction: true });
                if (this.props.checkboxText !== undefined) {
                  this.props.onClick(this.state.checkBoxChecked);
                } else {
                  this.props.onClick();
                }
              }}
            >
              {this.props.waiting ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  {this.props.textOnApiCall !== undefined
                    ? this.props.textOnApiCall
                    : ""}
                </>
              ) : (
                "Smazat " + this.props.modalItemTypeLabel
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default ApiCallButtonWithModal;
