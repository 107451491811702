import ReactGA from "react-ga";

export function signIn(
  email,
  password,
  pre_api_callback,
  then_callback,
  error_callback,
  finally_callback,
  context
) {
  pre_api_callback();
  global.api
    .post("/api/v1/user/login", {
      email: email,
      password: password
    })
    .then(response => {
      // context.addMessage("success", "Úspěšně jste se přihlásili do administrace BookIt.");
      context.signIn(response.data.token);
      ReactGA.set({ userId: email });
      ReactGA.event({ category: "User", action: "Login" });
      then_callback(response);
    })
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        ReactGA.event({
          category: "Error",
          action: "User",
          label: error.response.toString()
        });
        switch (error.response.status) {
          case 401:
            context.addMessage(
              "danger",
              "Přihlášení se nezdařilo. Zkontrolujte prosím svůj přihlašovací email a heslo."
            );
            break;
          default:
            context.addMessage(
              "danger",
              "Přihlášení se nezdařilo. Zkuste to opakovat později."
            );
        }
      } else if (error.request) {
        ReactGA.event({
          category: "Error",
          action: "User",
          label: error.request.toString()
        });
        console.log(error.request);
      } else {
        ReactGA.event({
          category: "Error",
          action: "User",
          label: error.message
        });
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      context.signOut();
      error_callback(error);
    })
    .finally(() => finally_callback());
}

export function signUp(
  email,
  name,
  pre_api_callback,
  then_callback,
  error_callback,
  finally_callback,
  context
) {
  pre_api_callback();
  global.api
    .post("/api/v1/user/website_register", { email: email, name: name })
    .then(res => then_callback(res))
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        ReactGA.event({
          category: "Error",
          action: "UserRegisterForm",
          label: error.response.toString()
        });
        context.addMessage(
          "danger",
          "Při odesílání formuláře nastala chyba. Zkuste to prosím znovu."
        );
      } else if (error.request) {
        ReactGA.event({
          category: "Error",
          action: "UserRegisterForm",
          label: error.request.toString()
        });
        console.log(error.request);
      } else {
        ReactGA.event({
          category: "Error",
          action: "UserRegisterForm",
          label: error.message
        });
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      error_callback(error);
    })
    .finally(() => finally_callback());
}
