import React from "react";
import AdminListAllEventGroups from "../components/AdminListAllEventGroups";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import { AlertBarContext } from "../AlertBarContextProvider";
import { Redirect } from "react-router-dom";
import { loadOrganizationAdmin } from "../Api/Organization";
import ReactGA from "react-ga";

class AdminHomePage extends React.Component {
  static contextType = AlertBarContext;
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      events: null,
      formName: null,
      formEmail: null,
      formValid: false,
      organizationId: null,
      organizationName: ""
    };
    ReactGA.pageview("/admin");
  }

  componentDidMount() {
    this.loadOrganizationData();
  }

  loadOrganizationData() {
    if (this.context.state.isSignedIn) {
      loadOrganizationAdmin(
        () => this.setState({ isLoading: true }),
        res =>
          this.setState({
            organizationId: res.data.id,
            organizationName: res.data.name
          }),
        () => {},
        () => this.setState({ isLoading: false }),
        this.context
      );
    }
  }

  render() {
    if (this.context.state.isSignedIn) {
      return (
        <Container className="d-flex justify-content-center">
          <Col xs="12" sm="12" md="12" lg="12" xl="10">
            <legend className="border-bottom mb-4">
              Přehled vytvořených událostí - {this.state.organizationName}
            </legend>
            <AdminListAllEventGroups
              organizationId={this.state.organizationId}
              organizationName={this.state.organizationName}
            />
          </Col>
        </Container>
      );
    } else {
      return <Redirect to="/signin" />;
    }
  }
}

export default AdminHomePage;
