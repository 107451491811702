import React from "react";
import { AlertBarContext } from "../AlertBarContextProvider";
import { Redirect } from "react-router-dom";
import ReactGA from "react-ga";

class SignoutPage extends React.Component {
  static contextType = AlertBarContext;
  constructor(props) {
    super(props);
    ReactGA.pageview("/signout");
  }

  render() {
    this.context.signOut();
    return <Redirect to="/" />;
  }
}

export default SignoutPage;
