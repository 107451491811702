import React from "react";
import { Route, Switch } from "react-router-dom";
import { Redirect } from "react-router-dom";
import HomePage from "./pages/HomePage.js";
import TryitPage from "./pages/TryitPage.js";
import OrganizationPage from "./pages/OrganizationPage.js";
import SigninPage from "./pages/SigninPage.js";
import SignoutPage from "./pages/SignoutPage.js";
import AdminHomePage from "./pages/AdminHomePage";
import AdminCreateEventGroupPage from "./pages/AdminCreateEventGroupPage";
import EventGroupPage from "./pages/EventGroupPage";
import EventPage from "./pages/EventPage";
import AdminEventGroupDetailPage from "./pages/AdminEventGroupDetailPage";
import AdminEventGroupExportPage from "./pages/AdminEventGroupExportPage";
import CancelParticipationPage from "./pages/CancelParticipationPage";
import ForgotPasswordRequestPage from "./pages/ForgotPasswordRequestPage";
import ForgotPasswordFormPage from "./pages/ForgotPasswordFormPage";
import AdminChangePasswordPage from "./pages/AdminChangePasswordPage";
import { AlertBarContext } from "./AlertBarContextProvider";
import FaqPage from "./pages/FaqPage";

class NotFound extends React.Component {
  static contextType = AlertBarContext;

  render() {
    this.context.addMessage(
      "info",
      "Bohužel takovou adresu neznáme a proto jsme Vás raději přesměrovali na úvodní stránku."
    );
    return <Redirect to="/" />;
  }
}

const createRoutes = () => (
  <Switch>
    <Route path="/" exact component={HomePage} />
    <Route path="/faq" exact component={FaqPage} />
    <Route path="/tryit/:email" exact component={TryitPage} />
    <Route
      path="/organization/:organizationId"
      exact
      component={OrganizationPage}
    />
    <Route path="/signout" exact component={SignoutPage} />
    <Route path="/signin/" exact component={SigninPage} />
    <Route
      path="/signin/reset_request"
      exact
      component={ForgotPasswordRequestPage}
    />
    <Route
      path="/signin/reset_request/:token"
      exact
      component={ForgotPasswordFormPage}
    />
    <Route
      path="/admin/create/:eventGroup"
      exact
      component={AdminCreateEventGroupPage}
    />
    <Route
      path="/admin/event_group/:eventGroup"
      exact
      component={AdminEventGroupDetailPage}
    />
    <Route
      path="/admin/event_group/export/:eventGroupId"
      exact
      component={AdminEventGroupExportPage}
    />
    <Route path="/admin" exact component={AdminHomePage} />
    <Route
      path="/admin/change_password"
      exact
      component={AdminChangePasswordPage}
    />
    <Route path="/events/:eventGroupId" exact component={EventGroupPage} />
    <Route path="/event/:eventId" exact component={EventPage} />
    <Route
      path="/cancel/:participationId"
      exact
      component={CancelParticipationPage}
    />

    {/* Finally, catch all unmatched routes */}
    <Route component={NotFound} />
  </Switch>
);

export default createRoutes;
