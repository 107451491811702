import React from "react";
import Form from "react-bootstrap/esm/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import "react-datepicker/dist/react-datepicker.css";
// use material theme
import "react-times/css/material/default.css";
import { AlertBarContext } from "../AlertBarContextProvider";
import ApiCallButton from "./ApiCallButton";
import GoBackButton from "../components/GoBackButton";
import { createEventGroup } from "../Api/EventGroup";
import Checkbox from "@material-ui/core/Checkbox";
import { FormControlLabel } from "@material-ui/core";

class CreateEventGroup extends React.Component {
  static contextType = AlertBarContext;

  DURATION_LIST = [5, 10, 15, 20, 30, 45, 60, 90, 120];

  constructor(props) {
    super(props);

    const duration = this.DURATION_LIST[0];

    this.state = {
      eventGroupName:
        this.props.eventGroupType === "enrollment"
          ? "Zápis " + new Date().getFullYear()
          : "",
      message: "",
      duration: duration,
      defaultCapacity: 10,
      videoCall: false,
      isCreating: false
    };
  }

  handleEventGroupNameChange = event => {
    this.setState({ eventGroupName: event.target.value });
  };

  handleDurationClick = event => {
    this.setState({ duration: parseInt(event.target.value) });
  };

  handleCapacityChange = event => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      this.setState({ defaultCapacity: event.target.value });
    }
  };

  handleMessageChange = event => {
    this.setState({ message: event.target.value });
  };

  handleVideoCallCheckboxChange = event => {
    this.setState({ videoCall: event.target.checked });
  };

  createEnrollmentClick = () => {
    createEventGroup(
      this.state.eventGroupName,
      this.state.duration,
      this.state.message,
      this.props.eventGroupType,
      this.state.defaultCapacity,
      this.state.videoCall,
      () => this.setState({ isCreating: true }),
      res => this.props.onEventCreated(res.data.id),
      () => {},
      () => this.setState({ isCreating: false }),
      this.context
    );
  };

  renderForm() {
    const eventNameLabel = (type => {
      switch (type) {
        case "enrollment":
          return "Název události";
        case "meeting":
          return "Název třídy / Jméno učitele";
        case "course":
          return "Název kroužku";
        default:
          return "Název události";
      }
    })(this.props.eventGroupType);

    const descriptionPlaceholder = (type => {
      switch (type) {
        case "enrollment":
          return "Popisek události, který se zobrazí rodičům při přihlašování.\nNapř. 'Prosím, přineste s sebou vyplněné formuláře.'";
        case "meeting":
          return "Popisek události, který se zobrazí rodičům při přihlašování.\nNapř. 'Schůzka probíhá ve 3. patře, místnost 309.'";
        case "course":
          return "Popisek události, který se zobrazí rodičům při přihlašování.\nNapř. 'Cena kroužku: 390,- / rok'";
        default:
          return "Popisek události, který se zobrazí rodičům při přihlašování.&#13;&#10;Např. 'Schůzka probíhá ve 3. patře, místnost 309.'";
      }
    })(this.props.eventGroupType);

    const createButtonLabel = (type => {
      switch (type) {
        case "enrollment":
          return "Vytvořit zápis";
        case "meeting":
          return "Vytvořit schůzku";
        case "course":
          return "Vytvořit kroužek";
        default:
          return "Vytvořit událost";
      }
    })(this.props.eventGroupType);

    return (
      <Form>
        <Form.Group md="4" controlId="courseName">
          <Form.Label>{eventNameLabel}</Form.Label>
          <Form.Control
            value={this.state.eventGroupName}
            onChange={this.handleEventGroupNameChange}
            type="text"
          />
        </Form.Group>
        <Form.Group md="4" controlId="capacity">
          <Form.Label>Popis události</Form.Label>
          <Form.Control
            value={this.state.message}
            onChange={this.handleMessageChange}
            as="textarea"
            rows="3"
            placeholder={descriptionPlaceholder}
          />
        </Form.Group>
        <Form.Group md="4" controlId="exampleForm.ControlSelect1">
          <Form.Row>
            <Col>
              <Form.Label>Délka trvání</Form.Label>
              <Form.Control as="select" onClick={this.handleDurationClick}>
                {this.DURATION_LIST.map((item, index) => (
                  <option key={index}> {item} minut</option>
                ))}
              </Form.Control>
            </Col>
            <Col>
              <Form.Label>Výchozí kapacita</Form.Label>
              <Form.Control
                value={this.state.defaultCapacity}
                onChange={this.handleCapacityChange}
                type="text"
              />
            </Col>
          </Form.Row>
        </Form.Group>
        <FormControlLabel
          control={
            <Checkbox
              checked={this.state.videoCall}
              onChange={this.handleVideoCallCheckboxChange}
              name="videoCallCheckbox"
              color="primary"
            />
          }
          label="Připojit k termínům odkaz na videohovor"
        />
        <div className="text-center align-middle">
          <p>
            Kliknutím na tlačítko vytvoříte novou událost a přejdete k zadání
            termínů.
          </p>
          <ApiCallButton
            onClick={this.createEnrollmentClick}
            disabled={
              isNaN(parseInt(this.state.defaultCapacity)) ||
              parseInt(this.state.defaultCapacity) === 0 ||
              this.state.eventGroupName.trim().length === 0
            }
            waiting={this.state.isCreating}
            text={createButtonLabel}
            textOnApiCall="Vytvářím událost"
          />
        </div>
      </Form>
    );
  }

  render() {
    const createEventLabel = (type => {
      switch (type) {
        case "enrollment":
          return "Vytvořit zápis";
        case "meeting":
          return "Vytvořit schůzku";
        case "course":
          return "Vytvořit kroužek";
        default:
          return "Vytvořit událost";
      }
    })(this.props.eventGroupType);

    return (
      <Container className="NewEnrollmentPage">
        <Row className="justify-content-md-center align-items-center pt-5">
          <Col md="9" className="pt-2 pb-2">
            <legend className="border-bottom mb-4">
              <GoBackButton /> {createEventLabel}
            </legend>
            {this.state.isCreating ? (
              <Spinner animation="grow" variant="primary" />
            ) : (
              this.renderForm()
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default CreateEventGroup;
