import React from "react";
import Button from "react-bootstrap/Button";
import { FaPlusImage, FaMinusImage } from "./Icons";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

function timestampToDate(unix_timestamp) {
  return new Date(unix_timestamp * 1000);
}

function convert_timestamp(date) {
  let hours = "0" + date.getHours();
  let minutes = "0" + date.getMinutes();
  return hours.substr(-2) + ":" + minutes.substr(-2);
}

const EventTimesTableRow = props => {
  return (
    <Col xs="6" sm="6" md="4" lg="3" xl="3" className="border text-center">
      <div>
        <Button
          className="m-2 align-middle"
          variant={props.enabled ? "success" : "danger"}
          onClick={props.onEventTimeClick}
        >
          {convert_timestamp(timestampToDate(props.startTime))} -{" "}
          {convert_timestamp(timestampToDate(props.endTime))}
        </Button>
      </div>
      {props.enabled ? (
        <>
          <div>{props.capacity}</div>
          <div>
            <Button
              variant="link"
              onClick={props.onCapacityDecrease}
              className="custom-dangerbutton-hover"
            >
              <FaMinusImage className="basic-icon" />
            </Button>
            <Button
              variant="link"
              onClick={props.onCapacityIncrease}
              className="custom-successbutton-hover"
            >
              <FaPlusImage className="basic-icon" />
            </Button>
          </div>
        </>
      ) : (
        <></>
      )}
    </Col>
  );
};

class EventTimesTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dummy: null
    };
  }

  render() {
    return (
      <Container>
        <Row>{this.renderBody()}</Row>
      </Container>
    );
  }

  renderBody = () => {
    const events = Array.from(this.props.eventsTimes);
    let table = [];
    for (let i = 0; i < events.length; i++) {
      table.push(
        <EventTimesTableRow
          key={`eventtimetablerow-${i}`}
          index={i}
          enabled={this.props.selected[i]}
          startTime={events[i]}
          endTime={events[i] + this.props.duration}
          capacity={
            this.props.capacities[i] !== null
              ? this.props.capacities[i]
              : this.props.defaultCapacity
          }
          onEventTimeClick={() => this.props.onEventTimeClick(i)}
          onCapacityIncrease={() => this.props.onCapacityIncrease(i)}
          onCapacityDecrease={() => this.props.onCapacityDecrease(i)}
        />
      );
    }
    return table;
  };
}

export default EventTimesTable;
