import Button from "react-bootstrap/Button";
import React from "react";
import Spinner from "react-bootstrap/Spinner";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

class ApiCallButton extends React.Component {
  /*
    props:
    * waiting
    * text
    * textOnApiCall
    * overlayText
    */

  render() {
    const toforwardprops = Object.assign({}, this.props);

    delete toforwardprops.waiting;
    delete toforwardprops.textOnApiCall;
    delete toforwardprops.text;
    if (this.props.overlayText === undefined || this.props.waiting) {
      return (
        <Button
          {...toforwardprops}
          disabled={this.props.waiting || this.props.disabled}
        >
          {this.props.waiting ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              {this.props.textOnApiCall !== undefined
                ? this.props.textOnApiCall
                : ""}
            </>
          ) : (
            this.props.text
          )}
        </Button>
      );
    } else {
      return (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="EventTooltip">{this.props.overlayText}</Tooltip>
          }
        >
          <Button
            {...toforwardprops}
            disabled={this.props.waiting || this.props.disabled}
          >
            {this.props.waiting ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                {this.props.textOnApiCall !== undefined
                  ? this.props.textOnApiCall
                  : ""}
              </>
            ) : (
              this.props.text
            )}
          </Button>
        </OverlayTrigger>
      );
    }
  }
}

export default ApiCallButton;
