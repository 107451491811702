import React from "react";
import { FaLeftImage } from "./Icons";
import Button from "react-bootstrap/Button";
import { withRouter } from "react-router-dom";

class GoBackButton extends React.Component {
  goback = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <Button
        onClick={this.goback}
        variant="link"
        className="d-inline custom-primarybutton-hover custom-backbutton"
      >
        <FaLeftImage className="basic-icon" />
      </Button>
    );
  }
}

export default withRouter(GoBackButton);
