import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import AdditionalForm from "../components/AdditionalForm";
import Button from "react-bootstrap/Button";
import { AlertBarContext } from "../AlertBarContextProvider";
import { ReCaptcha } from "react-recaptcha-google";
import ApiCallButton from "../components/ApiCallButton";
import GoBackButton from "../components/GoBackButton";
import { loadOrganizationByEvent } from "../Api/Organization";
import { participate } from "../Api/Participation";
import ReactGA from "react-ga";

class EventPage extends React.Component {
  static contextType = AlertBarContext;

  constructor(props, context) {
    super(props, context);

    this.state = {
      isLoading: false,
      eventGroupName: null,
      eventGroupLocked: false,
      events: null,
      eventGroupId: props.match.params.eventGroupId,
      eventId: props.match.params.eventId,
      formValid: false,
      result: null,
      recaptchaToken: null,
      organizationName: null,
      organizationEmail: null,
      organizationId: null
    };
    ReactGA.pageview("/event");
  }

  componentDidMount() {
    if (this.captchaDemo) {
      console.log("Obtaining recaptcha token...");
      this.captchaDemo.reset();
      this.captchaDemo.execute();
    }
    this.loadOrganizationData();
  }

  onLoadRecaptcha = () => {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
      this.captchaDemo.execute();
    }
  };

  verifyCallback = recaptchaToken => {
    this.setState({ recaptchaToken: recaptchaToken });
  };

  loadOrganizationData = () => {
    loadOrganizationByEvent(
      this.state.eventId,
      () => this.setState({ isLoading: true }),
      res =>
        this.setState({
          organizationEmail: res.data.email,
          organizationId: res.data.id,
          organizationName: res.data.name
        }),
      () => {},
      () => this.setState({ isLoading: false }),
      this.context
    );
  };

  onAdditionalFormChange = formState => {
    this.setState({
      formName: formState.name,
      formEmail: formState.email,
      formValid: formState.valid,
      formPhone: formState.phone,
      formMessage: formState.message
    });
  };

  onParticipate = () => {
    if (this.state.recaptchaToken) {
      participate(
        this.state.eventId,
        this.state.formName,
        this.state.formEmail,
        this.state.formMessage.length > 0 ? this.state.formMessage : null,
        this.state.formPhone.length > 0 ? this.state.formPhone : null,
        () => this.setState({ isLoading: true }),
        () =>
          this.setState({
            result:
              "Přihlášení proběhlo úspěšně. Na email jsme Vám zaslali potvrzení."
          }),
        () => this.setState({ result: "Přihlášení se nezdařilo." }),
        () => this.setState({ isLoading: false }),
        this.context
      );
    } else {
      this.context.addMessage(
        "danger",
        "Nebylo možné vás verifikovat pomocí reCaptcha"
      );
      this.setState({ isLoading: false });
    }
  };

  render() {
    return (
      <>
        <ReCaptcha
          ref={el => {
            this.captchaDemo = el;
          }}
          size="invisible"
          render="explicit"
          sitekey="6LeNoMEUAAAAAGcFGclqKEW7ghKlhrzOxZd8_lfc"
          onloadCallback={this.onLoadRecaptcha}
          verifyCallback={this.verifyCallback}
        />
        <Container className="d-flex justify-content-center">
          <Col xs="12" sm="10" md="10" lg="8" xl="6">
            <legend className="border-bottom mb-4">
              {this.state.result ? "" : <GoBackButton />}
              &nbsp;Přihlášení na událost
            </legend>
            <p>Škola: {this.state.organizationName}</p>
            {this.state.result ? (
              <>
                <p>{this.state.result}</p>
                <Link
                  key="linkback"
                  to={`/organization/${this.state.organizationId}`}
                >
                  <Button variant="link">Zpět na seznam událostí</Button>
                </Link>
              </>
            ) : (
              <div className="mt-2 mb-4">
                <AdditionalForm
                  disabled={this.state.isLoading}
                  onValueChange={this.onAdditionalFormChange}
                />
                <ApiCallButton
                  waiting={this.state.isLoading}
                  text="Odeslat přihlášení"
                  textOnApiCall="Přihlašuji"
                  disabled={!this.state.formValid || !this.state.recaptchaToken}
                  onClick={this.onParticipate}
                />
              </div>
            )}
          </Col>
        </Container>
      </>
    );
  }
}

export default EventPage;
