import React from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";

class Events extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dummy: null
    };
  }

  renderButton = event => {
    const diff = event.capacity - event.occupancy;
    let variant = "succes";
    let utilization;
    const expired =
      parseInt(event.start) * 1000 - new Date().getTime() < 7200 * 1000;
    if (diff === 0 || diff <= 0) {
      variant = "danger";
    } else if (diff === 1 && event.capacity > 1) {
      variant = "warning";
    } else if (diff === 1 && event.capacity === 1) {
      variant = "success";
    } else {
      variant = "success";
    }
    if (this.props.eventGroupType === "meeting") {
      utilization = diff <= 0 ? "obsazeno" : "volno";
    } else {
      utilization = event.occupancy + " / " + event.capacity;
    }
    if (this.props.eventGroupLocked) {
      utilization = "uzamčeno";
      variant = "warning";
    }
    /* TODO -- something like this
        if (expired) {
            utilization = "";
            variant = "warning"
        } */
    const link_to =
      diff <= 0 || this.props.eventGroupLocked || expired
        ? "#"
        : "/event/" + event.id;
    return (
      <Link key={event.id} to={link_to}>
        <Button
          variant={variant}
          disabled={diff <= 0 || this.props.eventGroupLocked || expired}
          className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-4 m-1 p-1 text-center align-middle"
          key={`key-${event.id}`}
        >
          {this.convert_timestamp(parseInt(event.start))} -{" "}
          {this.convert_timestamp(parseInt(event.end))}
          <br />
          <small>{utilization}</small>
        </Button>
      </Link>
    );
  };

  convert_datestamp = unix_timestamp => {
    const date = new Date(unix_timestamp * 1000);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return day + ". " + month + ". " + year;
  };

  convert_timestamp = unix_timestamp => {
    const date = new Date(unix_timestamp * 1000);
    const hours = date.getHours();
    const minutes = "0" + date.getMinutes();
    return hours + ":" + minutes.substr(-2);
  };

  renderDay = day => {
    const r = this.props.events.filter(event => {
      return this.convert_datestamp(event.start) === day;
    });
    return this.props.events ? (
      <div key={day}>
        <legend className="mt-4 mb-1">{day}</legend>
        {r.map(event => this.renderButton(event))}
      </div>
    ) : (
      <p>No events available.</p>
    );
  };

  renderResult() {
    const days = Array.from(
      new Set(
        this.props.events.map(event => this.convert_datestamp(event.start))
      )
    );
    return days.map(day => this.renderDay(day));
  }

  render() {
    return this.props.isLoading ? (
      <Spinner animation="grow" variant="primary" />
    ) : (
      this.renderResult()
    );
  }
}

export default Events;
