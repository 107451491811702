import React from "react";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import { AlertBarContext } from "../AlertBarContextProvider";
import { Redirect } from "react-router-dom";
import { getEventsByEventGroup } from "../Api/Event";
import { getParticipantsByEvent } from "../Api/Participation";
import { getEventGroup } from "../Api/EventGroup";
import ReactGA from "react-ga";

function compareFunc(a, b) {
  if (a.event.start < b.event.start) {
    return -1;
  }
  if (a.event.start > b.event.start) {
    return 1;
  }
  if (a.id < b.id) {
    return -1;
  }
  if (a.id > b.id) {
    return 1;
  }
  return 0;
}

class AdminEventGroupExportData extends React.Component {
  static contextType = AlertBarContext;

  constructor(props) {
    super(props);

    this.state = {
      eventGroupId: props.eventGroupId,
      eventGroupName: "",
      events: [],
      participants: [],
      isLoading: true
    };
    ReactGA.pageview("/admin/event_group/export");
  }

  componentDidMount() {
    this.loadEventGroup();
    this.loadParticipants();
  }

  loadEventGroup = () => {
    getEventGroup(
      this.state.eventGroupId,
      () => {},
      res => this.setState({ eventGroupName: res.data.name }),
      () => {},
      () => {},
      this.context
    );
  };

  processParticipants = (participants, event) => {
    participants.forEach(participant => (participant.event = event));
    participants.forEach(participant => (participant.rowspan = null));
    if (participants.length > 0) {
      participants[0].rowspan = event.capacity;
    } else {
      participants.push({
        event: event,
        name: "-",
        email: "",
        phone: "",
        message: "",
        rowspan: event.capacity
      });
    }
    const toFill = event.capacity - participants.length;
    if (toFill > 0) {
      for (let i = 0; i < toFill; i++) {
        participants.push({
          event: event,
          name: "-",
          email: "",
          phone: "",
          message: "",
          rowspan: null
        });
      }
    }
    participants.forEach((participant, i) => (participant.id = i + 1));
    participants.forEach(
      (participant, i) => (participant.uid = event.id + i + 1)
    );
    return participants;
  };

  loadParticipants = () => {
    getEventsByEventGroup(
      this.props.eventGroupId,
      () => this.setState({ isLoading: true }),
      res =>
        res.data.forEach(e => {
          getParticipantsByEvent(
            e.id,
            () => {},
            res => {
              const participants = this.processParticipants(res.data, e);
              this.setState({
                participants: this.state.participants.concat([...participants])
              });
            },
            () => {},
            () =>
              setTimeout(() => {
                this.setState({ isLoading: false });
              }, 2000),
            this.context
          );
        }),
      () => {},
      () => {},
      this.context
    );
  };

  convertTimestampToDate = unix_timestamp => {
    const date = new Date(unix_timestamp * 1000);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}. ${month}. ${year}`;
  };

  convertTimestampToTime = unix_timestamp => {
    const date = new Date(unix_timestamp * 1000);
    const hours = date.getHours();
    const minutes = "0" + date.getMinutes();
    return `${hours}:${minutes.substr(-2)}`;
  };

  renderEvent = participant => {
    if (participant.rowspan) {
      return (
        <tr key={participant.uid}>
          <td rowSpan={participant.rowspan}>
            {this.convertTimestampToDate(participant.event.start)}
          </td>
          <td rowSpan={participant.rowspan}>
            {this.convertTimestampToTime(participant.event.start)}
          </td>
          <td rowSpan={participant.rowspan}>
            {this.convertTimestampToTime(participant.event.end)}
          </td>
          <td>{participant.id}</td>
          <td>{participant.name}</td>
          <td>{participant.email}</td>
          <td>{participant.phone}</td>
          <td>{participant.message}</td>
        </tr>
      );
    } else {
      return (
        <tr key={participant.uid}>
          <td>{participant.id}</td>
          <td>{participant.name}</td>
          <td>{participant.email}</td>
          <td>{participant.phone}</td>
          <td>{participant.message}</td>
        </tr>
      );
    }
  };

  renderParticipants() {
    return this.state.participants
      .sort(compareFunc)
      .map(participant => this.renderEvent(participant));
  }

  renderTable() {
    return (
      <div className="export-pagebreak">
        <h1>{this.state.eventGroupName}</h1>
        <table className="export-table">
          <thead>
            <tr>
              <th colSpan={3}>Událost</th>
              <th colSpan={5}>Účastníci</th>
            </tr>
            <tr>
              <th>Den</th>
              <th>Od</th>
              <th>Do</th>
              <th>#</th>
              <th>Jméno</th>
              <th>Email</th>
              <th>Telefon</th>
              <th>Zpráva</th>
            </tr>
          </thead>
          <tbody>{this.renderParticipants()}</tbody>
        </table>
      </div>
    );
  }

  render() {
    return this.state.isLoading ? (
      <div>
        <p>Vydržte prosím, generujeme pro Vás výpis účastníků...</p>
        <Spinner animation="grow" variant="primary" />
      </div>
    ) : (
      this.renderTable()
    );
  }
}

class AdminEventGroupExportPage extends React.Component {
  static contextType = AlertBarContext;

  constructor(props) {
    super(props);

    this.state = {
      eventGroupId: props.match.params.eventGroupId,
      isLoading: true
    };
  }

  render() {
    const d = new Date().toLocaleString();
    if (this.context.state.isSignedIn) {
      return (
        <Container className="export-page">
          <p>Vygenerováno: {d}</p>
          <AdminEventGroupExportData eventGroupId={this.state.eventGroupId} />
        </Container>
      );
    } else {
      return <Redirect to="/signin" />;
    }
  }
}

export default AdminEventGroupExportPage;
