import axios from "axios/index";

let ApiUrl;

if (window.location.hostname === "localhost") {
  ApiUrl = "http://localhost:5000";
} else if (window.location.hostname === "localhost.dev") {
  ApiUrl = "https://dev.bookit.safio.cz";
} else {
  ApiUrl =
    window.location.protocol +
    "//" +
    window.location.hostname +
    (window.location.port ? ":" + window.location.port : "");
}

const api = axios.create({
  baseURL: ApiUrl,
  timeout: 0,
  headers: { "Content-Type": "application/json" }
});

export default api;
