import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReactGA from "react-ga";

class Question extends React.Component {
  render() {
    return (
      <Row
        className={`justify-content-center align-items-center text-justify pt-4 pb-3 ${this.props.className}`}
      >
        <Col xs="12" sm="12" md="10" lg="10" xl="8" className="m-1 order-1">
          {this.props.children}
        </Col>
      </Row>
    );
  }
}

class FaqPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true
    };
    ReactGA.pageview("/faq");
  }

  render() {
    return (
      <Container className="FaqPage justify-content-center">
        <Row className="justify-content-center align-items-center text-justify pt-3">
          <Col xs="12" sm="12" md="10" lg="10" xl="5" className="m-1 order-1">
            <h2>Často kladené dotazy</h2>
            <p>
              Na této stránce najdete nejčastěji kladené dotazy a odpovědi na
              ně. Neváhejte nás kontaktovat na{" "}
              <a href="mailto:bookit@safio.cz">bookit@safio.cz</a>, pokud zde
              nenajdete odpověď na svou otázku.
            </p>
          </Col>
          <Col xs="12" sm="12" md="8" lg="7" xl="3" className="m-1 order-2">
            <img
              className="d-block w-50 m-auto p-3"
              src="/029-questions.png"
              alt="img"
            />
          </Col>
        </Row>
        <hr className="hr-wide" />
        <Question>
          <h3>Aplikace se nezobrazí korektně.</h3>
          <p>
            Toho chování se projevuje ve velmi starých verzích prohlížečů,
            nejčastěji u prohlížeče Internet Explorer. Aplikace by měla fungovat
            pro všechny aktuální verze prohlížečů jako je Edge, Chrome, Firefox
            nebo Safari.
          </p>
          <p>Vyzkoušejte aplikaci otevřít v jednom ze zmíněných prohlížečů.</p>
        </Question>
        <Question>
          <h3>Aplikace nefunguje dle očekávání.</h3>
          <p>
            Toho chování se projevuje ve velmi starých verzích prohlížečů,
            nejčastěji u prohlížeče Internet Explorer. Aplikace by měla fungovat
            pro všechny aktuální verze prohlížečů jako je Edge, Chrome, Firefox
            nebo Safari.
          </p>
          <p>Vyzkoušejte aplikaci otevřít v jednom ze zmíněných prohlížečů.</p>
        </Question>
        <Question>
          <h3>Je možné mít mezi jednotlivými termíny přestávky?</h3>
          <p>Přestávky mezi termíny momentálně nelze vytvářet automaticky.</p>
          <p>
            Nyní je nutné vytvořit termíny na několik kliknutí na tlačítko "+"
            (přidat termín) s požadovanými rozestupy.
          </p>
        </Question>
        <Question>
          <h3>Je možné spravovat administraci školy z několika účtů?</h3>
          <p>Bohužel, v současné době to není možné.</p>
          <p>
            Uvědomujeme si, že zejména pro větší školy je tato funkcionalita
            důležitá a od začátku školního roku 2020/2021 bude tato možnost
            existovat.
          </p>
        </Question>
        <Question>
          <h3>Je možné změnit název školy?</h3>
          <p>
            Bohužel, v současné době není možné v administraci změnit název
            školy. Tato funkcionalita bude dostupná od začátku školního roku
            2020/2021.
          </p>
          <p>
            Napište nám prosím email na{" "}
            <a href="mailto:bookit@safio.cz">bookit@safio.cz</a> a my Vám název
            školy rádi změníme.
          </p>
        </Question>
        <Question>
          <h3>Narazil jsem na chybu v aplikaci.</h3>
          <p>
            Jako u každého softwaru se i v naší aplikaci může objevit chyba.
          </p>
          <p>
            Budeme rádi, když nám chybu nahlásíte na{" "}
            <a href="mailto:bookit@safio.cz">bookit@safio.cz</a> a pomůžete nám
            tak urychlit její opravení.
          </p>
        </Question>
        <Question>
          <h3>
            Proč není možné přihlásit se na termín později než dvě hodiny před
            jeho začátkem?
          </h3>
          <p>
            Tímto omezením chceme předejít změnám na poslední chvíli a nelze jej
            obejít. Pokud chcete možnost přihlašování ukončit dříve, tak je
            nutné v administraci aplikace kliknout na "Zamknout událost".
          </p>
        </Question>
      </Container>
    );
  }
}

export default FaqPage;
