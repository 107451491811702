import React from "react";
import { AlertBarContext } from "../../AlertBarContextProvider";
import EventDetail from "./EventDetail";
import Spinner from "react-bootstrap/Spinner";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import {
  decrementCapacity,
  deleteEvent,
  getEventsByEventGroup,
  incrementCapacity
} from "../../Api/Event";

class EventDetailList extends React.Component {
  static contextType = AlertBarContext;

  constructor(props) {
    super(props);
    this.state = {
      events: [],
      loadingEvents: true,
      deletingEvent: false,
      changingCapacity: false,
      days: []
    };
  }

  handleDeleteEvent = (event_id, notify = false) => {
    deleteEvent(
      event_id,
      notify,
      () => this.setState({ deletingEvent: true }),
      () => {
        const events_copy = [...this.state.events];
        const event = events_copy.filter(event => event.id === event_id);
        const index = events_copy.indexOf(event[0]);
        events_copy.splice(index, 1);
        const days = Array.from(
          new Set(
            Array.from(events_copy).map(event =>
              this.convertTimestampToDate(event.start)
            )
          )
        );
        this.setState({ events: events_copy, days: days });
      },
      () => {},
      () => this.setState({ deletingEvent: false }),
      this.context
    );
  };

  handleDecreaseEventCapacity = event_id => {
    decrementCapacity(
      event_id,
      () => this.setState({ changingCapacity: true }),
      () => {
        const events_copy = [...this.state.events];
        const event = events_copy.filter(event => event.id === event_id);
        const index = events_copy.indexOf(event[0]);
        events_copy[index].capacity -= 1;
        this.setState({ events: events_copy });
      },
      () => {},
      () => this.setState({ changingCapacity: false }),
      this.context
    );
  };

  handleIncreaseEventCapacity = event_id => {
    incrementCapacity(
      event_id,
      () => this.setState({ changingCapacity: true }),
      () => {
        const events_copy = [...this.state.events];
        const event = events_copy.filter(event => event.id === event_id);
        const index = events_copy.indexOf(event[0]);
        events_copy[index].capacity += 1;
        this.setState({ events: events_copy });
      },
      () => {},
      () => this.setState({ changingCapacity: false }),
      this.context
    );
  };

  componentDidMount() {
    this.loadEvents();
  }

  loadEvents = () => {
    getEventsByEventGroup(
      this.props.eventGroupId,
      () => this.setState({ loadingEvents: true }),
      res => {
        const events = res.data;
        const days = Array.from(
          new Set(
            Array.from(events).map(event =>
              this.convertTimestampToDate(event.start)
            )
          )
        );
        this.setState({ events: events, days: days });
      },
      () => {},
      () => this.setState({ loadingEvents: false }),
      this.context
    );
  };

  convertTimestampToDate = unix_timestamp => {
    const date = new Date(unix_timestamp * 1000);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}. ${month}. ${year}`;
  };

  renderDay = day => {
    const events = this.state.events.filter(
      event => this.convertTimestampToDate(event.start) === day
    );
    return events.map(event => (
      <EventDetail
        event={event}
        key={`event-${event.id}`}
        onEventDelete={notify => this.handleDeleteEvent(event.id, notify)}
        deletingEvent={this.state.deletingEvent}
        onEventCapacityIncrease={() =>
          this.handleIncreaseEventCapacity(event.id)
        }
        onEventCapacityDecrease={() =>
          this.handleDecreaseEventCapacity(event.id)
        }
        changingCapacity={this.state.changingCapacity}
        videoCallUrl={event.video_call_url}
      />
    ));
  };

  render() {
    return this.state.loadingEvents ? (
      <Spinner animation="grow" variant="primary" />
    ) : (
      <Accordion>
        {this.state.days.map((day, i) => (
          <Card key={day}>
            <Accordion.Toggle as={Card.Header} eventKey={i.toString()}>
              {day}
              <span className="float-right">
                {
                  this.state.events.filter(
                    event => this.convertTimestampToDate(event.start) === day
                  ).length
                }{" "}
                termínů
              </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={i.toString()}>
              <Card.Body>{this.renderDay(day)}</Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    );
  }
}
export default EventDetailList;
