import ReactGA from "react-ga";

export function changePasswordByToken(
  token,
  newPwd,
  pre_api_callback,
  then_callback,
  error_callback,
  finally_callback,
  context
) {
  pre_api_callback();
  global.api
    .post("/api/v1/user/change_pwd/" + token, { new_pwd: newPwd })
    .then(response => {
      then_callback(response);
      context.addMessage(
        "success",
        "Heslo bylo změněno. Nyní se prosím přihlašte."
      );
      ReactGA.event({
        category: "User",
        action: "Modify",
        label: "Change password"
      });
    })
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        ReactGA.event({
          category: "Error",
          action: "User",
          label: error.response.toString()
        });
        switch (error.response.status) {
          case 401:
            context.addMessage(
              "danger",
              "Změna hesla se nezdařila. Zkuste to prosím znovu."
            );
            break;
          default:
            context.addMessage(
              "danger",
              "Změna hesla se nezdařila. Zkuste to prosím znovu."
            );
        }
      } else if (error.request) {
        console.log(error.request);
        ReactGA.event({
          category: "Error",
          action: "User",
          label: error.request.toString()
        });
      } else {
        ReactGA.event({
          category: "Error",
          action: "User",
          label: error.message
        });
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      error_callback();
    })
    .finally(() => finally_callback());
}

export function changePassword(
  oldPwd,
  newPwd,
  pre_api_callback,
  then_callback,
  error_callback,
  finally_callback,
  context
) {
  global.api
    .post("/api/v1/admin/change_pwd", {
      old_pwd: oldPwd,
      new_pwd: newPwd
    })
    .then(response => {
      then_callback(response);
      context.addMessage("success", "Změna hesla proběhla úspěšně.");
      ReactGA.event({
        category: "User",
        action: "Modify",
        label: "Change password"
      });
    })
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        ReactGA.event({
          category: "Error",
          action: "User",
          label: error.response.toString()
        });
        switch (error.response.status) {
          case 401:
            context.addMessage(
              "danger",
              "Změna hesla selhala. Zadané heslo není správné."
            );
            break;
          default:
            context.addMessage(
              "danger",
              "Změna hesla selhala. Zkuste to prosím znovu."
            );
        }
      } else if (error.request) {
        ReactGA.event({
          category: "Error",
          action: "User",
          label: error.request.toString()
        });
        console.log(error.request);
      } else {
        ReactGA.event({
          category: "Error",
          action: "User",
          label: error.message
        });
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      error_callback();
    })
    .finally(() => finally_callback());
}

export function resetPassword(
  email,
  pre_api_callback,
  then_callback,
  error_callback,
  finally_callback,
  context
) {
  pre_api_callback();
  global.api
    .post("/api/v1/user/reset_pwd/" + email)
    .then(response => {
      then_callback(response);
      context.addMessage(
        "success",
        "Žádost o resetování hesla přijata. Podívejte se prosím do svého emailu a postupujte dle instrukcí."
      );
      ReactGA.event({
        category: "User",
        action: "Modify",
        label: "Reset password"
      });
    })
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        ReactGA.event({
          category: "Error",
          action: "User",
          label: error.response.toString()
        });
        switch (error.response.status) {
          case 401:
            context.addMessage(
              "danger",
              "Resetování hesla se nezdařilo. Zkontrolujte prosím svůj přihlašovací email."
            );
            break;
          default:
            context.addMessage(
              "danger",
              "Resetování hesla se nezdařilo. Zkontrolujte prosím svůj přihlašovací email."
            );
        }
      } else if (error.request) {
        ReactGA.event({
          category: "Error",
          action: "User",
          label: error.request.toString()
        });
        console.log(error.request);
      } else {
        ReactGA.event({
          category: "Error",
          action: "User",
          label: error.message
        });
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      error_callback();
    })
    .finally(() => finally_callback());
}
