import React from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import locale from "date-fns/locale/cs";
import "bootstrap/dist/css/bootstrap.css";
import "./custom.css";
import Navigation from "./components/Navigation.js";
import Routes from "./Routes";
import { BrowserRouter as Router } from "react-router-dom";
import { loadReCaptcha } from "react-recaptcha-google";
import AlertBar from "./components/AlertBar";
import AlertBarContextProvider from "./AlertBarContextProvider";
// Ugly hack to initialize global API
import API from "./Api/Api.js";
import ReactGA from "react-ga";

global.api = API;

const GoogleAnalyticsTrackingId =
  window.location.hostname === "bookit.safio.cz"
    ? "UA-159991681-1"
    : "UA-159991681-2";

function initializeGoogleAnalytics() {
  ReactGA.initialize(GoogleAnalyticsTrackingId);
}

function getCookie(cname) {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

global.isSignedIn = function() {
  if (global.api.defaults.headers.common["Authorization"] === undefined) {
    if (getCookie("Authorization") !== "") {
      global.api.defaults.headers.common["Authorization"] = getCookie(
        "Authorization"
      );
      return true;
    }
    return false;
  } else {
    return true;
  }
};

global.signIn = function(token) {
  document.cookie = "Authorization=" + token;
  global.api.defaults.headers.common["Authorization"] = token;
};

global.signOut = function() {
  document.cookie =
    "Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  global.api.defaults.headers.common["Authorization"] = undefined;
};

class App extends React.Component {
  constructor(props) {
    super(props);
    initializeGoogleAnalytics();
  }

  componentDidMount() {
    loadReCaptcha();
  }

  render() {
    return (
      <div className="App">
        <Router>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
            <AlertBarContextProvider>
              <Navigation />
              <AlertBar />
              <Routes />
            </AlertBarContextProvider>
          </MuiPickersUtilsProvider>
        </Router>
        <footer className="text-center footer mt-auto py-3 text-dark">
          <hr className="hr-short" />
          <div className="container">
            &copy; {new Date().getFullYear()}, Created by{" "}
            <a href="https://www.safio.cz/">Safio.cz</a>
          </div>
        </footer>
      </div>
    );
  }
}

export default App;
