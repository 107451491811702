import React from "react";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import { AlertBarContext } from "../AlertBarContextProvider";
import ApiCallButton from "../components/ApiCallButton";
import Button from "react-bootstrap/Button";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { signIn } from "../Api/SignIn";
import ReactGA from "react-ga";

class SigninPage extends React.Component {
  static contextType = AlertBarContext;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      password: "",
      remember: false,
      signedInSuccess: false
    };
    ReactGA.pageview("/signin");
  }

  handleSubmit = async event => {
    event.preventDefault();
    signIn(
      this.state.email,
      this.state.password,
      () => this.setState({ isLoading: true }),
      () => this.setState({ signedInSuccess: true }),
      () => {},
      () => this.setState({ isLoading: false }),
      this.context
    );
  };

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  renderForm() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Group controlId="email">
          <Form.Control
            type="email"
            placeholder="Přihlašovací email"
            required
            onChange={this.handleChange}
          />
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Control
            type="password"
            placeholder="Heslo k účtu"
            required
            onChange={this.handleChange}
          />
        </Form.Group>
        <ApiCallButton
          variant="primary"
          type="submit"
          waiting={this.state.isLoading}
          text="Přihlásit se"
          textOnApiCall="Přihlašuji ..."
        />
        <Link to="/signin/reset_request">
          <Button size="sm" variant="link" className="ml-2">
            Nedaří se Vám přihlásit?
          </Button>
        </Link>
      </Form>
    );
  }

  render() {
    if (this.context.state.isSignedIn) {
      return <Redirect to="/admin" />;
    } else if (this.state.signedInSuccess) {
      return <Redirect to="/admin" />;
    } else {
      return (
        <Container className="d-flex align-items-center justify-content-center h-50">
          <Col xs="12" sm="10" md="8" lg="6" xl="5">
            <legend className="border-bottom mb-4">
              Přihlášení do administrace
            </legend>
            {this.renderForm()}
          </Col>
        </Container>
      );
    }
  }
}

export default SigninPage;
