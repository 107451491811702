import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SalesStory from "../components/SalesStory";
import Image from "react-bootstrap/Image";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";
import UserRegisterForm from "../components/UserRegisterForm";

class HomePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true
    };
    ReactGA.pageview("/");
  }

  render() {
    return (
      <Container className="HomePage">
        <SalesStory />

        <hr className="hr-wide" />

        <Row className="justify-content-center align-items-end text-center pt-3 pb-3">
          <Col xs="10" sm="10" md="10" lg="10" xl="10" className="pb-2">
            <h2>Ceník</h2>
          </Col>
          <Col xs="10" sm="10" md="10" lg="10" xl="10">
            <p>
              Věříme, že naše aplikace pomůže malým i velkým školám, a proto
              počítáme cenu podle velikosti školy.
            </p>
          </Col>

          <Col
            xs="10"
            sm="10"
            md="4"
            lg="3"
            xl="3"
            className="justify-content-center align-items-end pb-4"
          >
            <Image
              className="d-block m-auto p-3 custom-img-small"
              src="/008-university.png"
            />
            <p>Počet žáků menší než 200</p>
            <strong>1000 Kč</strong>
          </Col>
          <Col
            xs="10"
            sm="10"
            md="4"
            lg="3"
            xl="3"
            className="justify-content-center align-items-end pb-4"
          >
            <Image
              className="d-block m-auto p-3 custom-img-medium"
              src="/008-university.png"
            />
            <p>Počet žáků v rozmezí 200&nbsp;až&nbsp;600</p>
            <strong>2000 Kč</strong>
          </Col>
          <Col
            xs="10"
            sm="10"
            md="4"
            lg="3"
            xl="3"
            className="justify-content-center align-items-end pb-4"
          >
            <Image
              className="d-block m-auto p-3 custom-img-large"
              src="/008-university.png"
            />
            <p>Počet žáků větší než 600</p>
            <strong>3000 Kč</strong>
          </Col>
          <Col xs="10" sm="10" md="10" lg="10" xl="10">
            Výše uvedený ceník je platný od školního roku 2021/2022 a do konce
            školního roku 2020/2021 je aplikace k dispozici zdarma.
            <br />
            Uvedené ceny se vztahují na jeden školní rok, jsou konečné, bez
            dalších poplatků a včetně DPH.
          </Col>
        </Row>

        <hr className="hr-wide" />

        <Row className="justify-content-center align-items-center pt-3">
          <Col xs="10" sm="10" md="10" lg="10" xl="10" className="text-center">
            <h2 className="text-center" id="tryit">
              Chcete si aplikaci vyzkoušet?
            </h2>
            <p>Vytvořte si účet zdarma.</p>
          </Col>
        </Row>
        <Row className="justify-content-center align-items-center">
          <Col xs="12" sm="10" md="7" lg="6" xl="4">
            {<UserRegisterForm />}
          </Col>
        </Row>

        <hr className="hr-wide" />

        <Row className="justify-content-center align-items-center pt-3">
          <Col xs="10" sm="10" md="10" lg="10" xl="10" className="text-center">
            <h2 className="text-center" id="tryit">
              Často kladené dotazy
            </h2>
            <p>
              Pokud máte dotaz a potřebujete rychlou odpověď, můžete se podívat
              do nové sekce, kde najdete&nbsp;
              <Link key="faqlink" to="/faq">
                často kladené dotazy
              </Link>
              &nbsp;nebo přímo do&nbsp;
              <a key="manuallink" href="/api/static/manual.pdf">
                příručky k aplikaci
              </a>
              .
            </p>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default HomePage;
