import React from "react";
import { Redirect } from "react-router-dom";
import { AlertBarContext } from "../AlertBarContextProvider";
import CreateEventGroup from "../components/CreateEventGroup";
import ReactGA from "react-ga";

class AdminCreateEventGroupPage extends React.Component {
  static contextType = AlertBarContext;

  constructor(props) {
    super(props);

    this.state = {
      eventGroup: props.match.params.eventGroup,
      isLoading: true,
      isCreated: false,
      createdId: null
    };
    ReactGA.pageview("/admin/create");
  }

  handleEventCreated = newId => {
    this.context.addMessage(
      "success",
      "Nová událost vytvořena, níže můžete přidat termíny."
    );
    this.setState({ isCreated: true, createdId: newId });
  };

  render() {
    if (this.context.state.isSignedIn) {
      if (this.state.isCreated) {
        return <Redirect to={`/admin/event_group/${this.state.createdId}`} />;
      }

      return (
        <CreateEventGroup
          eventGroupType={this.state.eventGroup}
          onEventCreated={this.handleEventCreated}
        />
      );
    } else {
      return <Redirect to="/signin" />;
    }
  }
}

export default AdminCreateEventGroupPage;
