import React, { Component, createContext } from "react";

export const AlertBarContext = createContext();

export const DEFAULT_DELAY = 10000;

class AlertBarContextProvider extends Component {
  state = {
    messages: [],
    isSignedIn: global.isSignedIn()
  };

  addMessage = (type, message, delay = DEFAULT_DELAY) => {
    this.setState({ messages: [...this.state.messages, { type, message }] });
    if (delay) {
      setTimeout(() => this.cleanMessage(message), delay);
    }
  };

  cleanAll = () => {
    this.setState({ messages: [] });
  };

  cleanMessage = message => {
    this.setState({
      messages: this.state.messages.filter(item => item.message !== message)
    });
  };

  signIn = token => {
    global.signIn(token); //potential hazard
    this.setState({ isSignedIn: true });
  };

  signOut = () => {
    global.signOut(); //potential hazard
    this.setState({ isSignedIn: false });
  };

  render() {
    return (
      <AlertBarContext.Provider
        value={{
          state: this.state,
          addMessage: this.addMessage,
          cleanAll: this.cleanAll,
          cleanMessage: this.cleanMessage,
          signIn: this.signIn,
          signOut: this.signOut
        }}
      >
        {this.props.children}
      </AlertBarContext.Provider>
    );
  }
}

export default AlertBarContextProvider;
