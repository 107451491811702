import React from "react";

import Image from "react-bootstrap/Image";

// Our set of icons
//
// Simple usage:
// import { faUserIcon } from "./Icons";
// <Image className="profile-avatar" src={faUserIcon()} roundedCircle />
//
// Advanced usage:
// import { FaUserImage } from "./Icons";
// <FaUserImage roundedCircle className="profile-avatar" />

function faUserIcon() {
  return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAM1BMVEX///8zMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzOYH8YOAAAAEXRSTlMAIIDA/xCgMPBA4HCwYFDQkHGUKkwAAADtSURBVHgB7dVFmgQhDAXgV/BCJ7Te/7Ljhs2XbEbrX+NEsNt9akuZT3LaECeFHxRB0EHZ0ANCNmVHN0RkDjICjBMGv8KJAr/KiQo/TsFNvn0B7AugcKrA6ciFI3xOXCjwSVxI8Nm4sMEpcyrDyzhlcDsrB3pGgIX37yk7ipjMTkZMYich5sLOBTHCjiCoslERldhIiBLlByoIkHTp+rMeAFySOKfr84ZSlE+0yPORNIk3Da6CjlxdCXGufHaVYf6zesaa1T75ZulZDXOW2ag3MwBidqtsZMPEmQFnjJQBihFDfugC+wKJAQmN3e4ePkIVptKFsJ0AAAAASUVORK5CYII=";
}

function FaUserImage(props) {
  return <Image src={faUserIcon()} {...props} />;
}

export { faUserIcon, FaUserImage };

function faBinIcon() {
  return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABABAMAAABYR2ztAAAAFVBMVEX///8zMzMzMzMzMzMzMzMzMzMzMzOZaqduAAAAB3RSTlMAgP/QUBDgIZBeDAAAAGFJREFUeAFjGIZASAkIFPEoUAID2igQUsIAimhaMcEgUTDwgIArh6gCE2cEgVWBAwuCwKqAgRVBYFWATNBYwaiCUQWYiZZwsqd/1ktCl1dDU+CErkAFTQFLEpoBDijSowAAFAZbCiz5HJkAAAAASUVORK5CYII=";
}

function FaBinImage(props) {
  return <Image src={faBinIcon()} {...props} />;
}

export { faBinIcon, FaBinImage };

function faLockIcon() {
  return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAJ1BMVEX///8zMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzM4yZTiAAAADXRSTlMAIJDQ/xBQ4PCAQDCgr33sRQAAAK1JREFUeNrtldEOwyAIRVEQ0fb/v3epSZt1KSuTNHvhPN7giRIiEAQqKSN9gDmBmUyXZLBRkBSwgAUkFXTc3/yKRF9JjgsMsq0Dni4clfye8uGFG8peyOecfxao+R1aYQhsAq5korJynsxcGyqZqcoI2XlEACF4RiCt9ybzAlm2fJFpQYNBmxZ0GHS3wP2E/zWRZMvFM0gjD4FPsAKsFD/SMwL3anMvV/d6D4IzL8vXG2QG/dTCAAAAAElFTkSuQmCC";
}

function FaLockImage(props) {
  return <Image src={faLockIcon()} {...props} />;
}

export { faLockIcon, FaLockImage };

function faUnlockIcon() {
  return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAJ1BMVEX///8zMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzM4yZTiAAAADXRSTlMAIJDQ/xBQ4PCAQDCgr33sRQAAALJJREFUeNrtldsKwzAIQE205tL+//eOEhbWEVcXKaXgeTTmYAwoDAkR6QuMAdREGhJBx4IkgAtoQBJBQ/3qVwT6SdAVIBN1HbB0oWcyfMDde/qH70SGA/y3QIyfISU+R9BxgQtuE3AiFYmF+6RmbEikJgmjRM8lAnDBNYJcai15XpDXPb7maUFpB2VaUNtBNQvMT7iviZT3uOEbWyK5wCbYADbyiXSNwLzazMvVvN4d58gLMJscJLDexPQAAAAASUVORK5CYII=";
}

function FaUnlockImage(props) {
  return <Image src={faUnlockIcon()} {...props} />;
}

export { faUnlockIcon, FaUnlockImage };

function faPenIcon() {
  return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAALVBMVEX///8zMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMEkjCrAAAADnRSTlMAMPAQ0KDAkCBgQIDgULibBtcAAACoSURBVHja7ZU5DsMwEMR0WJft7P+f6ybBQKWowNWyJ7GQAU9wnPeJyaxs+iowXwXs80I12yvk45+FVTfWPBfW3++YCmnZt6mQ4rKvAvVVgL4KlfkqBOKrwHxRN/0U3Xff/Zf8UKmv9cC+CtxvWQXid/372f0N7Y/ICe2PGGx/xIH2R5xsPUSxvUI30Ujg+tmp9UC4v+cP3Q++YTkD5WN2X7qdFEZwHMoDTFgcu+BWjAAAAAAASUVORK5CYII=";
}

function FaPenImage(props) {
  return <Image src={faPenIcon()} {...props} />;
}

export { faPenIcon, FaPenImage };

function faCheckIcon() {
  return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAM1BMVEX///8zMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzOYH8YOAAAAEHRSTlMAYPAwENCg4MCAkHBAUCCwHRL/JwAAANlJREFUeF7tlMuqwzAMBS2/EufRzv9/7V3cQqyNi6xVwbOfITgHhV9lsVikjf1w+FcEqNO+8M/sNxx82Kf0/MYVyAVXIMXHjy+7f/d+svtCx2X3Kx1i1vNOx2n2X4WO6np+eJt9UX7JVv/E51cevg/gugfr/e4LsIlar/a5h/4F6ESKYBhQBJ0QNC2MAVSiAaYBgUpUACwXoKDBOoA8KmzKNxRsFyRHj69/vEZmDqf2XYVj4np4LpAMB2QvlBxchah8eyGmMEXjQwqT1GcAnoIEB2drp0FYLBZ//yMjqdzCJ6oAAAAASUVORK5CYII=";
}

function FaCheckImage(props) {
  return <Image src={faCheckIcon()} {...props} />;
}

export { faCheckIcon, FaCheckImage };

function faPrintIcon() {
  return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABABAMAAABYR2ztAAAAGFBMVEX///8zMzMzMzMzMzMzMzMzMzMzMzMzMzOcv8ImAAAAB3RSTlMAUOCAoBDQiQ4OrgAAAHpJREFUeF7t1TEKhjAMhuGv4L93+nuEHsHVq3gE45Dru5WmiRmCg0jf+YFAIARDs5W7Tg1+LAoAfBK8rlTZiHIDhc2OBqoNqAG+yQMbFh8ACAB/hC4GJtgh+iuQJUgKYCgCuOtxUG1ADRQbHOL0NKAMmV7yC8HqfIPZBcOUaTi8y4S1AAAAAElFTkSuQmCC";
}

function FaPrintImage(props) {
  return <Image src={faPrintIcon()} {...props} />;
}

export { faPrintIcon, FaPrintImage };

function faShareIcon() {
  return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABABAMAAABYR2ztAAAAHlBMVEX///8zMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzOpnVyeAAAACXRSTlMAwEBgINDwULBmtq0gAAAAuUlEQVR4Xu2VMQrCQBREP8HgASxkO69gZzob+xRewk7P4AmWhcDcVvgowWLyIHWmfY8N2fn5iaVsOUuHJX6TpLvnu4ckTVcr9MqMVhiUaVYoylQr6JvVQgdCV34C8QpcDbhG4NPV8vrKsjw/xSXr/u//PXPT/9Hz7F+yPHrgMQCPAjyAz8IzQHAH8CMKGPCacFFw1VAW1c0DgyOHQ8tjD0bjT4+MMNmTECsEWGKwBnmR8irmZc6/gy0fJYP6bOAVYSMAAAAASUVORK5CYII=";
}

function FaShareImage(props) {
  return <Image src={faShareIcon()} {...props} />;
}

export { faShareIcon, FaShareImage };

function faCancelIcon() {
  return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAALVBMVEX///8zMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMEkjCrAAAAD3RSTlMAQIDA4P8woPAgsFCQYBDpn2HFAAABNklEQVR42u1W25aEIAxbKagjhP//3NmzOna5VJmTV/OmkABpC/158OASkxMf/uDFTd+y52UNBdZl/mZxCR3I6DZeWzCwvUb4cQ0m1njPT+ES6Y6/BIW4OP0iOgmKZZTvkfV3hh9TSEqvh+AHThHPVXI7mM/dRTN+H//RH8cnFlY0N+VfK2xG/in/TqGfk1K6nAQnT1IZJenWz+F/1qk4+Cqbj1jMdgrg/xcOvirAToY9BL5MKBwU5fg9EKaFqAoCqMoAlo1uH8jqZw3ZXdg/nBED0ZM2QDmxhi+E0efrVn0jUB0Nfb4WjClgKKB2uymkxlyUfENAwQjwR1CBOGwiHUY6kehUpouJLWf6QqGvNPZSpa919mGhnzb2cWWfd7bBYFsctsli2zy20SRbXbLZJtv9Bw+u8Aaszxzg+cb3ZAAAAABJRU5ErkJggg==";
}

function FaCancelImage(props) {
  return <Image src={faCancelIcon()} {...props} />;
}

export { faCancelIcon, FaCancelImage };

function faLeftIcon() {
  return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAFVBMVEX///8zMzMzMzMzMzMzMzMzMzMzMzOZaqduAAAABnRSTlMA0BCgsMARkx4/AAAAW0lEQVR42u3WMQ7AMAzDQCdq+f8n5wkduLSotB8Bb56u+872ItJDpIdID5EeIj1EerB+Wb/r63/k55J+bud9wJ+wVwsttPBUGFvwD4YrxD9ZqhD7aOpXt+tevQPhDgxpUsZH8AAAAABJRU5ErkJggg==";
}

function FaLeftImage(props) {
  return <Image src={faLeftIcon()} {...props} />;
}

export { faLeftIcon, FaLeftImage };

function faRightIcon() {
  return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAFVBMVEX///8zMzMzMzMzMzMzMzMzMzMzMzOZaqduAAAAB3RSTlMAoP/QELDAw89K2wAAAF9JREFUeNrt1jEOgDAMBMHgBP7/5LijpJgGxF2/KzlNdmTZu3fUXMZXG4gnQ/NkaJ4MzZuhCg1TDSuGGGJ4MpwDDRcL+AR+xPDhf8T754q8B4YnjkfWnXkampy6WfaZbUxrBdFbIVJ1AAAAAElFTkSuQmCC";
}

function FaRightImage(props) {
  return <Image src={faRightIcon()} {...props} />;
}

export { faRightIcon, FaRightImage };

function faUpIcon() {
  return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABABAMAAABYR2ztAAAAFVBMVEX///8zMzMzMzMzMzMzMzMzMzMzMzOZaqduAAAABnRSTlMA0BCgsMARkx4/AAAAkUlEQVR4Xu3MoQ3EMBBE0VXSgYGx3UHQdXAdpAEr0vZfwoEF32C05EDIfjgaPfujqjq+90iB6d5SoLuvkQNBJACEBoJIAQgNQGgAQgMQGoBIAAgNQGhgdQgJtAkhgXFogpmrBvavBjTBZppg2gkjgJ0w+gBAPEYnAMQVAERjmAAQAUAEABEARAB0BgBx2StV1Q/LZGl1vtLr6wAAAABJRU5ErkJggg==";
}

function FaUpImage(props) {
  return <Image src={faUpIcon()} {...props} />;
}

export { faUpIcon, FaUpImage };

function faDownIcon() {
  return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAFVBMVEX///8zMzMzMzMzMzMzMzMzMzMzMzOZaqduAAAAB3RSTlMAoP/QELDAw89K2wAAAJBJREFUeNrt0CEOwDAMBMHGSfr/JxcucNyTDhRUPmrtAF+9Xq/3342Yq76uGUP1gXDqI4boEY49Qt0j5B6h7hFyj1D3CLkXQsRZoGdHYCah7OfbkxHUSQu6V4LqtaB6LaheC6LXgui1kHpXoPcFei24PYLfI/g9gt8j0HsCvSfQewK9K9Cbwr7vva5er9f7ZA8m6wXR66SMXgAAAABJRU5ErkJggg==";
}

function FaDownImage(props) {
  return <Image src={faDownIcon()} {...props} />;
}

export { faDownIcon, FaDownImage };

function faPlusIcon() {
  return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABAAgMAAADXB5lNAAAACVBMVEX///8zMzMzMzOVNhaoAAAAAnRSTlMAgJsrThgAAAAuSURBVHgBYxhqgG3VqgkoAlyrVi0YoQJsq+BgAlQWDhYMWgFMp49GJSJpDzEAAOfclxkDZ4kbAAAAAElFTkSuQmCC";
}

function FaPlusImage(props) {
  return <Image src={faPlusIcon()} {...props} />;
}

export { faPlusIcon, FaPlusImage };

function faMinusIcon() {
  return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABAAgMAAADXB5lNAAAACVBMVEX///8zMzMzMzOVNhaoAAAAAnRSTlMAgJsrThgAAAAfSURBVHgBYxjCYBRkrYKClVCBVXAwaAUwnT50wSgAAE2hTy97neVjAAAAAElFTkSuQmCC";
}

function FaMinusImage(props) {
  return <Image src={faMinusIcon()} {...props} />;
}

export { faMinusIcon, FaMinusImage };

function faEmailIcon() {
  return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAIVBMVEX///8zMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzP96u4PAAAACnRSTlMAMPAQ0KAgYECQT65r2AAAAMtJREFUeNrt1sEOgjAQRVFBCtj//2BDGnMXZTLPvA2LmQ0Rck9QQuurpqbmYfPe+l/TTKEZ90CfCXqP4PQLgtIvE7AiKP06AR1B6fsMICj9DYAg9HcAQt4HAELSB0Ai0AdAKtDHAELShwBC3MdALNArAMLcywACvQDQI9AjKD/iPgT6HUF6jOPQrhOfkSqPkZ4vws0jxAA9AhmC9jKt5+/aOSIE9XXejuvzwQkEe0HxlzR7UbWXdXtjMbc2f3N1t3ejR3D/4tTU1DxsvqxUMoD6uDXMAAAAAElFTkSuQmCC";
}

function FaEmailImage(props) {
  return <Image src={faEmailIcon()} {...props} />;
}

export { faEmailIcon, FaEmailImage };

function faVideoCallIcon() {
  return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAAA0AgMAAABHtNaaAAAACVBMVEUAAAAAAAD///+D3c/SAAAAAXRSTlMAQObYZgAAAGNJREFUKM/d0rENgDAMRNG48AZ4H0ag4PZfBUQEke4j0YGEO78iUu7c2jujc5a+5wXqUAPmexj78chkEKtByqAMQgbpUAYhg3SoPwN+izyQGDNF6uiFzaHbvf3ng8GN4Qq/mQ1I3q9mCYqy+AAAAABJRU5ErkJggg==";
}

function FaVideoCallImage(props) {
  return <Image src={faVideoCallIcon()} {...props} />;
}

export { faVideoCallIcon, FaVideoCallImage };
