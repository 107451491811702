import ReactGA from "react-ga";

export function loadOrganization(
  organizationId,
  pre_api_callback,
  then_callback,
  error_callback,
  finally_callback,
  context
) {
  pre_api_callback();
  global.api
    .get("/api/v1/user/organization/" + organizationId)
    .then(res => {
      then_callback(res);
    })
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        ReactGA.event({
          category: "Error",
          action: "Organization",
          label: error.response.toString()
        });
        // only one possible error
        context.addMessage(
          "danger",
          "Při načítání nastala chyba. Zkuste to prosím znovu."
        );
      } else if (error.request) {
        ReactGA.event({
          category: "Error",
          action: "Organization",
          label: error.request.toString()
        });
        console.log(error.request);
      } else {
        ReactGA.event({
          category: "Error",
          action: "Organization",
          label: error.message
        });
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      error_callback();
    })
    .finally(() => finally_callback());
}

export function loadOrganizationAdmin(
  pre_api_callback,
  then_callback,
  error_callback,
  finally_callback,
  context
) {
  pre_api_callback();
  global.api
    .get("/api/v1/admin/organization")
    .then(res => then_callback(res))
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        ReactGA.event({
          category: "Error",
          action: "Organization",
          label: error.response.toString()
        });
        // only one possible error
        context.addMessage(
          "danger",
          "Při načítání nastala chyba. Zkuste to prosím znovu."
        );
      } else if (error.request) {
        ReactGA.event({
          category: "Error",
          action: "Organization",
          label: error.request.toString()
        });
        console.log(error.request);
      } else {
        ReactGA.event({
          category: "Error",
          action: "Organization",
          label: error.message
        });
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      error_callback();
    })
    .finally(() => finally_callback());
}

export function loadOrganizationByEvent(
  eventId,
  pre_api_callback,
  then_callback,
  error_callback,
  finally_callback,
  context
) {
  pre_api_callback();
  global.api
    .get("/api/v1/user/organization_by_event/" + eventId)
    .then(res => then_callback(res))
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        ReactGA.event({
          category: "Error",
          action: "Organization",
          label: error.response.toString()
        });
        switch (error.response.status) {
          case 404:
            context.addMessage(
              "danger",
              "Při načítání nastala chyba. Termín nebyl nalezen."
            );
            break;
          default:
            context.addMessage(
              "danger",
              "Při načítání nastala chyba. Zkuste to prosím znovu."
            );
        }
      } else if (error.request) {
        ReactGA.event({
          category: "Error",
          action: "Organization",
          label: error.request.toString()
        });
        console.log(error.request);
      } else {
        ReactGA.event({
          category: "Error",
          action: "Organization",
          label: error.message
        });
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      error_callback();
    })
    .finally(() => finally_callback());
}

export function loadOrganizationByEventGroup(
  eventGroupId,
  pre_api_callback,
  then_callback,
  error_callback,
  finally_callback,
  context
) {
  pre_api_callback();
  global.api
    .get("/api/v1/user/organization_by_eventgroup/" + eventGroupId)
    .then(res => then_callback(res))
    .catch(error => {
      if (error.response) {
        console.log(error.response);
        ReactGA.event({
          category: "Error",
          action: "Organization",
          label: error.response.toString()
        });
        switch (error.response.status) {
          case 404:
            context.addMessage(
              "danger",
              "Při načítání nastala chyba. Událost nebyla nalezena."
            );
            break;
          default:
            context.addMessage(
              "danger",
              "Při načítání nastala chyba. Zkuste to prosím znovu."
            );
        }
      } else if (error.request) {
        ReactGA.event({
          category: "Error",
          action: "Organization",
          label: error.request.toString()
        });
        console.log(error.request);
      } else {
        ReactGA.event({
          category: "Error",
          action: "Organization",
          label: error.message
        });
        console.log("Error", error.message);
        console.log("Error", error.config);
      }
      error_callback();
    })
    .finally(() => finally_callback());
}
