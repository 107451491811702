import React from "react";
import Button from "react-bootstrap/Button";
import { AlertBarContext } from "../../AlertBarContextProvider";
import ApiCallButton from "../ApiCallButton";
import ApiCallButtonWithModal from "../ApiCallButtonWithModal";
import { Redirect } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {
  FaBinImage,
  FaLockImage,
  FaUnlockImage,
  FaPrintImage,
  FaShareImage,
  FaVideoCallImage
} from "../Icons";
import EventGroupDescription from "./EventGroupDescription";
import EventDetailList from "./EventDetailList";
import AddEvent from "./AddEvent";
import EventGroupName from "./EventGroupName";
import Spinner from "react-bootstrap/Spinner";
import {
  deleteEventGroup,
  getEventGroupAdmin,
  lockEventGroup,
  unlockEventGroup
} from "../../Api/EventGroup";
import { InfoCircleFill } from "react-bootstrap-icons";

class EventGroupDetail extends React.Component {
  static contextType = AlertBarContext;

  constructor(props) {
    super(props);

    this.state = {
      duration: null,
      name: null,
      description: null,
      locked: null,
      events: [],
      loaded: false,
      loadingFail: false,
      lockingEventGroup: false,
      deletingEventGroup: false,
      updateTime: null,
      isDeletedSuccess: false,
      defaultCapacity: null,
      videoCall: false
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    getEventGroupAdmin(
      this.props.eventGroupId,
      () => {},
      res =>
        this.setState({
          name: res.data.name,
          duration: res.data.duration,
          description: res.data.description,
          locked: res.data.locked,
          eventGroupId: res.data.id,
          defaultCapacity: res.data.default_capacity,
          loaded: true,
          loadingFail: false,
          updateTime: Math.floor(Date.now() / 1000),
          videoCall: res.data.video_call
        }),
      () => this.setState({ loadingFail: true }),
      () => this.setState({ loaded: true }),
      this.context
    );
  };

  deleteEventGroup = (notify = false) => {
    deleteEventGroup(
      this.state.eventGroupId,
      notify,
      () => this.setState({ deletingEventGroup: true }),
      () => {
        this.context.addMessage("success", "Událost byla zrušena.");
        this.setState({ isDeletedSuccess: true });
      },
      () => {},
      () => this.setState({ deletingEventGroup: false }),
      this.context
    );
  };

  modifyEventGroupName = name => {
    this.setState({ name: name });
  };

  modifyEventGroupDescription = description => {
    this.setState({ description: description });
  };

  unlockEventGroup = () => {
    unlockEventGroup(
      this.state.eventGroupId,
      () => this.setState({ lockingEventGroup: true }),
      () => this.setState({ locked: false }),
      () => {},
      () => this.setState({ lockingEventGroup: false }),
      this.context
    );
  };

  lockEventGroup = () => {
    lockEventGroup(
      this.state.eventGroupId,
      () => this.setState({ lockingEventGroup: true }),
      () => this.setState({ locked: true }),
      () => {},
      () => this.setState({ lockingEventGroup: false }),
      this.context
    );
  };

  renderLoadedEventGroup = () => {
    return (
      <>
        <legend className="border-bottom mb-4">
          <EventGroupName
            key={`${this.state.name}_${this.state.updateTime}`}
            name={this.state.name}
            eventGroupId={this.props.eventGroupId}
            onNameChange={this.modifyEventGroupName}
          />
        </legend>
        <EventGroupDescription
          key={`${this.state.description}_${this.state.updateTime}`}
          description={this.state.description}
          eventGroupId={this.props.eventGroupId}
          onDescriptionChange={this.modifyEventGroupDescription}
        />
        <div>
          <strong>Délka trvání: </strong>
          {this.state.duration} minut
        </div>
        <div>
          <br />
          {this.state.videoCall ? (
            <p>
              K termínům jsou připojeny odkazy na videohovory.
              <br />
              Pro připojení k videohovoru si rozbalte seznam termínů a klikněte
              na ikonu&nbsp;
              <FaVideoCallImage className="basic-icon" />.
            </p>
          ) : (
            <p>K termínům nejsou připojeny odkazy na videohovory.</p>
          )}
        </div>
        {this.state.locked ? (
          <p class="text-danger mt-2">Událost je zamčena.</p>
        ) : (
          <></>
        )}
        <hr />
        {this.state.locked ? (
          <ApiCallButton
            className="mt-2 mr-2 custom-lockbutton-hover"
            variant="link"
            waiting={this.state.lockingEventGroup}
            onClick={this.unlockEventGroup}
            text={<FaUnlockImage className="basic-icon" />}
            textOnApiCall={<FaUnlockImage className="basic-icon" />}
            overlayText="Odemknout událost"
          />
        ) : (
          <ApiCallButton
            className="mt-2 mr-2 custom-lockbutton-hover"
            variant="link"
            waiting={this.state.lockingEventGroup}
            onClick={this.lockEventGroup}
            text={<FaLockImage className="basic-icon" />}
            textOnApiCall={<FaLockImage className="basic-icon" />}
            overlayText="Zamknout událost"
          />
        )}
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="deleteTooltip">Smazat událost</Tooltip>}
        >
          <ApiCallButtonWithModal
            className="mt-2 mr-2 custom-deletebutton-hover"
            variant="link"
            waiting={this.state.deletingEventGroup}
            onClick={this.deleteEventGroup}
            text={<FaBinImage className="basic-icon" />}
            textOnApiCall={<FaBinImage className="basic-icon" />}
            modalItemTypeLabel="událost"
            modalItemName={this.state.name}
            checkboxText={
              <div className={"px-2"}>
                <span className={"pr-2"}>Upozornit přihlášené?</span>
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="infoTooltip">
                      Emaily nebudou rozeslány, pro termíny událostí, které již
                      proběhly.
                    </Tooltip>
                  }
                >
                  <InfoCircleFill color="royalblue" />
                </OverlayTrigger>
              </div>
            }
          />
        </OverlayTrigger>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="showEventTooltip">Přejít na událost</Tooltip>}
        >
          <LinkContainer to={`/events/${this.props.eventGroupId}`}>
            <Button
              variant="link"
              className="mt-2 mr-2 custom-primarybutton-hover"
            >
              <FaShareImage className="basic-icon" />
            </Button>
          </LinkContainer>
        </OverlayTrigger>
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="exportEventTooltip">Exportovat účastníky</Tooltip>
          }
        >
          <LinkContainer
            to={`/admin/event_group/export/${this.props.eventGroupId}`}
          >
            <Button
              variant="link"
              className="mt-2 mr-2 custom-primarybutton-hover"
            >
              <FaPrintImage className="basic-icon" />
            </Button>
          </LinkContainer>
        </OverlayTrigger>
        <AddEvent
          eventGroupId={this.props.eventGroupId}
          onEventsAdded={this.loadData}
          duration={this.state.duration}
          defaultCapacity={this.state.defaultCapacity}
        />
        <hr />
        <EventDetailList
          key={this.state.updateTime}
          eventGroupId={this.props.eventGroupId}
        />
      </>
    );
  };

  render() {
    if (this.state.isDeletedSuccess) {
      return <Redirect to="/admin" />;
    }
    return this.state.loaded ? (
      this.state.loadingFail ? (
        <></>
      ) : (
        this.renderLoadedEventGroup()
      )
    ) : (
      <Spinner animation="grow" variant="primary" />
    );
  }
}

export default EventGroupDetail;
