import React from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import ApiCallButtonWithModal from "../ApiCallButtonWithModal";
import { AlertBarContext } from "../../AlertBarContextProvider";
import ApiCallButton from "../ApiCallButton";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import {
  FaBinImage,
  FaMinusImage,
  FaPlusImage,
  FaEmailImage,
  FaVideoCallImage
} from "../Icons";
import { InfoCircleFill } from "react-bootstrap-icons";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {
  deleteParticipation,
  getParticipantsByEvent
} from "../../Api/Participation";

function EventDetailParticipant(props) {
  return (
    <Col
      xs="12"
      sm="11"
      md="5"
      lg="5"
      xl="5"
      className="mt-2 mb-2 border text-center"
    >
      <div className="mt-2">{props.participant.name}</div>
      <div>
        <small>{props.participant.email}</small>
      </div>
      <div className="m-1">
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Napsat email</Tooltip>}
        >
          <Button
            href={"mailto:" + props.participant.email}
            className="custom-primarybutton-hover"
            variant="link"
          >
            {<FaEmailImage className="basic-icon" />}
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Smazat přihlášku</Tooltip>}
        >
          <ApiCallButtonWithModal
            className="custom-deletebutton-hover"
            variant="link"
            text={<FaBinImage className="basic-icon" />}
            textOnApiCall={<FaBinImage className="basic-icon" />}
            waiting={props.deleteParticipationLoading}
            onClick={props.onDeleteParticipation}
            modalItemTypeLabel="přihlášku"
            modalItemName={props.participant.name}
            checkboxText={
              <div className={"px-2"}>
                <span className={"pr-2"}>Upozornit účastníka?</span>
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="infoTooltip">
                      Email nebud odeslán, pokud termín již proběhl.
                    </Tooltip>
                  }
                >
                  <InfoCircleFill color="royalblue" />
                </OverlayTrigger>
              </div>
            }
          />
        </OverlayTrigger>
      </div>
    </Col>
  );
}

class EventDetail extends React.Component {
  static contextType = AlertBarContext;

  constructor(props) {
    super(props);

    this.state = {
      participants: null,
      occupancy: 0,
      participantsLoading: true,
      deletingParticipation: false
    };
  }

  componentDidMount() {
    this.loadParticipants();
  }

  loadParticipants = () => {
    getParticipantsByEvent(
      this.props.event.id,
      () => this.setState({ participantsLoading: true }),
      res =>
        this.setState({ participants: res.data, occupancy: res.data.length }),
      () => {},
      () => this.setState({ participantsLoading: false }),
      this.context
    );
  };

  convertTimestampToTime = unix_timestamp => {
    const date = new Date(unix_timestamp * 1000);
    const hours = date.getHours();
    const minutes = "0" + date.getMinutes();
    return `${hours}:${minutes.substr(-2)}`;
  };

  handleDeleteParticipation = (participant, notify) => {
    deleteParticipation(
      participant.id,
      notify,
      () => this.setState({ deletingParticipation: true }),
      () => this.loadParticipants(),
      () => {},
      () => this.setState({ deletingParticipation: false }),
      this.context
    );
  };

  renderParticipant = participant => {
    return (
      <EventDetailParticipant
        key={participant.id}
        participant={participant}
        onDeleteParticipation={notify =>
          this.handleDeleteParticipation(participant, notify)
        }
        deleteParticipationLoading={this.state.deletingParticipation}
      />
    );
  };

  renderParticipants() {
    const participants = this.state.participants.map(p =>
      this.renderParticipant(p)
    );
    const disabled = this.props.event.capacity < 2;
    const eventLabel =
      this.convertTimestampToTime(parseInt(this.props.event.start)) +
      " - " +
      this.convertTimestampToTime(parseInt(this.props.event.end));
    return (
      <Accordion>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="0">
            {eventLabel}
            <span className="float-right">
              <span className="mr-2">
                {this.props.videoCallUrl == null ? (
                  <></>
                ) : (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="videocallTooltip">
                        Připojit se k videohovoru
                      </Tooltip>
                    }
                  >
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={this.props.videoCallUrl}
                      className="custom-primarybutton-hover btn btn-link"
                    >
                      <FaVideoCallImage className="basic-icon" />
                    </a>
                  </OverlayTrigger>
                )}
                <ApiCallButton
                  className="custom-dangerbutton-hover"
                  variant="link"
                  onClick={this.props.onEventCapacityDecrease}
                  disabled={disabled}
                  text={<FaMinusImage className="basic-icon" />}
                  textOnApiCall={<FaMinusImage className="basic-icon" />}
                  waiting={this.props.changingCapacity}
                  overlayText="Snížit kapacitu"
                />
                <ApiCallButton
                  className="custom-successbutton-hover"
                  variant="link"
                  onClick={this.props.onEventCapacityIncrease}
                  text={<FaPlusImage className="basic-icon" />}
                  textOnApiCall={<FaPlusImage className="basic-icon" />}
                  waiting={this.props.changingCapacity}
                  overlayText="Zvýšit kapacitu"
                />
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id="deleteTooltip">Smazat událost</Tooltip>}
                >
                  <ApiCallButtonWithModal
                    className="custom-deletebutton-hover"
                    variant="link"
                    onClick={this.props.onEventDelete}
                    text={<FaBinImage className="basic-icon" />}
                    textOnApiCall={<FaBinImage className="basic-icon" />}
                    waiting={this.props.deletingEvent}
                    modalItemTypeLabel="termín"
                    modalItemName={eventLabel}
                    checkboxText={
                      <div className={"px-2"}>
                        <span className={"pr-2"}>Upozornit přihlášené?</span>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id="infoTooltip">
                              Emaily nebudou rozeslány, pokud termín již
                              proběhl.
                            </Tooltip>
                          }
                        >
                          <InfoCircleFill color="royalblue" />
                        </OverlayTrigger>
                      </div>
                    }
                  />
                </OverlayTrigger>
              </span>
              {this.state.occupancy} / {this.props.event.capacity}
            </span>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <Row className="justify-content-center">{participants}</Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  }

  render() {
    return this.state.participantsLoading ? (
      <Spinner animation="grow" variant="primary" />
    ) : (
      this.renderParticipants()
    );
  }
}

export default EventDetail;
