import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/esm/Form";
import { AlertBarContext } from "../AlertBarContextProvider";
import ApiCallButton from "../components/ApiCallButton";
import { cancel_participation } from "../Api/Participation";
import ReactGA from "react-ga";

class CancelParticipationPage extends React.Component {
  static contextType = AlertBarContext;

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      isLoading: false,
      cancelSent: false,
      cancelSuccessful: null,
      participationId: props.match.params.participationId
    };
    ReactGA.pageview("/cancel");
  }

  onEmailChange = event => {
    this.setState({ email: event.target.value });
  };

  onDiscardButtonClick = () => {
    cancel_participation(
      this.state.participationId,
      this.state.email,
      () => this.setState({ cancelSent: true, isLoading: true }),
      () => this.setState({ cancelSuccessful: true }),
      () => this.setState({ cancelSuccessful: false }),
      () => this.setState({ isLoading: false }),
      this.context
    );
  };

  renderDiscardCheck = () => {
    return (
      <>
        <legend className="border-bottom mb-4">
          Pro zrušení účasti, vložte Váš email
        </legend>
        <Form>
          <Form.Group controlId="form.email">
            <Form.Control
              type="email"
              placeholder="Email"
              value={this.state.email}
              onChange={this.onEmailChange}
            />
          </Form.Group>
          <ApiCallButton
            variant="primary"
            onClick={this.onDiscardButtonClick}
            text={"Zrušit účast"}
            textOnApiCall={"Ruším účast"}
            waiting={this.state.isLoading}
          />
        </Form>
      </>
    );
  };

  renderDiscardResult = () => {
    if (this.state.cancelSuccessful == null) {
      return <Spinner animation="grow" variant="primary" />;
    } else {
      return this.state.cancelSuccessful ? (
        <p>Vaše účast byla zrušena.</p>
      ) : (
        <p>Účast nebyla zrušena! Zadaný email neodpovídá emailu přihlášky.</p>
      );
    }
  };

  render() {
    return (
      <Container className="HomePage">
        <Row className="justify-content-md-center align-items-center pt-5">
          <Col md="9" className="pt-2 pb-2">
            {this.state.cancelSent
              ? this.renderDiscardResult()
              : this.renderDiscardCheck()}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default CancelParticipationPage;
