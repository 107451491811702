import React from "react";
import Form from "react-bootstrap/esm/Form";
import ApiCallButton from "../ApiCallButton";
import { FaCheckImage, FaPenImage } from "../Icons";
import Spinner from "react-bootstrap/Spinner";
import { modifyEventGroup } from "../../Api/EventGroup";
import { AlertBarContext } from "../../AlertBarContextProvider";

class EventGroupDescription extends React.Component {
  static contextType = AlertBarContext;

  constructor(props) {
    super(props);

    this.state = {
      updatingValue: false,
      editMode: false,
      editText: props.description
    };
  }

  modifyEventGroupDescriptionCall = description => {
    modifyEventGroup(
      this.props.eventGroupId,
      null,
      description,
      () => this.setState({ updatingValue: true, editMode: false }),
      res => {
        this.setState({ editText: description });
        this.props.onDescriptionChange(description);
      },
      () => {},
      () => this.setState({ updatingValue: false }),
      this.context
    );
  };

  onButtonClick = () => {
    const editMode = !this.state.editMode;
    if (editMode === false) {
      this.modifyEventGroupDescriptionCall(this.state.editText);
    }
    this.setState({ editMode: editMode });
  };

  render() {
    return (
      <>
        <strong>Popis události:</strong>
        <div className="text-justify">
          {this.state.editMode ? (
            <Form.Control
              className="w-75 d-inline"
              type="text"
              value={this.state.editText}
              as="textarea"
              onChange={event => {
                this.setState({ editText: event.target.value });
              }}
            />
          ) : this.state.editText.length === 0 ? (
            <span className="text-muted">Popis neuveden.</span>
          ) : (
            this.state.editText
          )}
          <ApiCallButton
            onClick={this.onButtonClick}
            variant="link"
            text={
              this.state.editMode ? (
                <FaCheckImage className="basic-icon" />
              ) : (
                <FaPenImage className="basic-icon" />
              )
            }
            textOnApiCall={<Spinner animation="grow" variant="primary" />}
            waiting={this.updatingValue}
          />
        </div>
      </>
    );
  }
}

export default EventGroupDescription;
