import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import ContactForm from "../components/ContactForm";
import ReactGA from "react-ga";

class TryitPage extends React.Component {
  constructor(props) {
    super(props);
    ReactGA.pageview("/tryit");
  }

  render() {
    return (
      <Container className="d-flex align-items-center justify-content-center h-50">
        <Col xs="12" sm="12" md="10" lg="7" xl="6">
          <h2 id="tryit">Přeji si vyzkoušet aplikaci BookIt</h2>
          <p>Potvrzením formuláře níže Vám zřídíme účet na uvedený email.</p>
          {<ContactForm email={this.props.match.params.email} />}
        </Col>
      </Container>
    );
  }
}

export default TryitPage;
