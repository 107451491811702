import React from "react";
import Form from "react-bootstrap/esm/Form";

class AdditionalForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      phone: "",
      message: "",
      valid: false
    };
  }

  onNameChange = event => {
    const valid = this.isValid(event.target.value, this.state.email);
    this.setState({ name: event.target.value, valid: valid }, () =>
      this.props.onValueChange(this.state)
    );
  };

  onEmailChange = event => {
    const valid = this.isValid(this.state.name, event.target.value);
    this.setState({ email: event.target.value, valid: valid }, () =>
      this.props.onValueChange(this.state)
    );
  };
  onPhoneChange = event => {
    this.setState({ phone: event.target.value }, () =>
      this.props.onValueChange(this.state)
    );
  };

  onMessageChange = event => {
    this.setState({ message: event.target.value }, () =>
      this.props.onValueChange(this.state)
    );
  };

  validateEmail = email => {
    //eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  validateName = name => {
    return name.split(" ").length > 1 && name.length > 5;
  };

  isValid = (name, email) => {
    return this.validateEmail(email) && this.validateName(name);
  };

  render() {
    return (
      <Form>
        <Form.Group controlId="form.name">
          <Form.Label>Jméno a příjmení žáka</Form.Label>
          <Form.Control
            as="input"
            type="text"
            value={this.state.name}
            disabled={this.props.disabled}
            onChange={this.onNameChange}
          />
        </Form.Group>
        <Form.Group controlId="form.email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            as="input"
            type="email"
            value={this.state.email}
            disabled={this.props.disabled}
            onChange={this.onEmailChange}
          />
        </Form.Group>
        <Form.Group controlId="form.phone">
          <Form.Label>
            Telefon <small>(volitelné)</small>
          </Form.Label>
          <Form.Control
            as="input"
            type="text"
            value={this.state.phone}
            disabled={this.props.disabled}
            onChange={this.onPhoneChange}
          />
        </Form.Group>
        <Form.Group controlId="form.message">
          <Form.Label>
            Zpráva pro učitele <small>(volitelné)</small>
          </Form.Label>
          <Form.Control
            as="textarea"
            type="textarea"
            autoComplete="none"
            rows="4"
            value={this.state.message}
            disabled={this.props.disabled}
            onChange={this.onMessageChange}
          />
        </Form.Group>
      </Form>
    );
  }
}

export default AdditionalForm;
