import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import { AlertBarContext } from "../AlertBarContextProvider";
import { FaUserImage } from "./Icons";
import { withRouter } from "react-router-dom";

class ProfileButton extends React.Component {
  render() {
    return (
      <Dropdown>
        <Dropdown.Toggle variant="link">
          <FaUserImage className="profile-avatar" roundedCircle />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Link to="/admin" className="dropdown-item">
            Administrace
          </Link>
          <Link to="/admin/change_password" className="dropdown-item">
            Změna hesla
          </Link>
          <Link to="/" className="dropdown-item">
            Úvodní stránka
          </Link>
          <Dropdown.Divider />
          <Link to="/signout" className="dropdown-item">
            Odhlásit
          </Link>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

class Navigation extends React.Component {
  static contextType = AlertBarContext;

  renderNav() {
    return (
      <Navbar className="justify-content-between">
        <Nav>
          <Navbar.Brand>
            <Link
              to={this.context.state.isSignedIn ? "/admin" : "/"}
              className="nav-link active"
            >
              BookIt{" "}
              {this.context.state.isSignedIn ? <small>admin</small> : <></>}
            </Link>
          </Navbar.Brand>
        </Nav>
        <Nav>{this.context.state.isSignedIn ? <ProfileButton /> : <></>}</Nav>
      </Navbar>
    );
  }

  render() {
    return this.props.location.pathname === "/" ? <></> : this.renderNav();
  }
}

export default withRouter(Navigation);
