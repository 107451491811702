import React from "react";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import ApiCallButton from "./ApiCallButton";
import { ReCaptcha } from "react-recaptcha-google";
import { contactForm } from "../Api/ContactForm";

class ContactForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: props.email ? props.email : "",
      phone: "",
      success: false,
      sent: false,
      recaptchaToken: null,
      validEmail: props.email ? true : false,
      contactFormChangeOccurred: props.email ? true : false
    };
  }

  initCaptcha = () => {
    this.setState({ contactFormChangeOccurred: true });
    if (this.captchaDemo) {
      console.log("Obtaining recaptcha token...");
      this.captchaDemo.reset();
      this.captchaDemo.execute();
    }
  };

  onLoadRecaptcha = () => {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
      this.captchaDemo.execute();
    }
  };

  verifyCallback = recaptchaToken => {
    this.setState({ recaptchaToken: recaptchaToken });
  };

  handleSubmit = async event => {
    event.preventDefault();
    contactForm(
      this.state.email,
      this.state.phone,
      () => this.setState({ isLoading: true }),
      () => this.setState({ success: true, sent: true }),
      () => this.setState({ success: false, sent: true }),
      () => this.setState({ isLoading: false }),
      this.context
    );
  };

  handlePhoneChange = event => {
    if (this.state.recaptchaToken === null) {
      this.initCaptcha();
    }
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  onEmailChange = event => {
    if (this.state.recaptchaToken === null) {
      this.initCaptcha();
    }
    const validEmail = this.validateEmail(event.target.value);
    this.setState({ email: event.target.value, validEmail: validEmail });
  };

  validateEmail = email => {
    //eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  renderForm() {
    return (
      <Form onSubmit={this.handleSubmit}>
        {this.state.contactFormChangeOccurred ? (
          <ReCaptcha
            ref={el => {
              this.captchaDemo = el;
            }}
            size="invisible"
            render="explicit"
            sitekey="6LeNoMEUAAAAAGcFGclqKEW7ghKlhrzOxZd8_lfc"
            onloadCallback={this.onLoadRecaptcha}
            verifyCallback={this.verifyCallback}
          />
        ) : (
          <></>
        )}
        <Form.Group controlId="email">
          <Form.Control
            type="email"
            placeholder="Váš email"
            required
            onChange={this.onEmailChange}
            defaultValue={this.state.email}
          />
        </Form.Group>
        <Form.Group controlId="phone">
          <Form.Control
            type="text"
            placeholder="Telefon (volitelné)"
            onChange={this.handlePhoneChange}
          />
        </Form.Group>
        <Form.Group controlId="formGridButton">
          <ApiCallButton
            variant="success"
            type="submit"
            className="mb-2 custom-button-rounded-corners"
            waiting={this.state.isLoading}
            disabled={!this.state.validEmail || !this.state.recaptchaToken}
            text="Kontaktujte mě"
            textOnApiCall="Odesílám ..."
          />
        </Form.Group>
      </Form>
    );
  }

  renderResult() {
    if (this.state.success) {
      return (
        <Alert key="form-msg" variant="success">
          Děkujeme za důvěru. Ozveme se Vám co nejdříve.
        </Alert>
      );
    } else {
      return (
        <Alert key="form-msg" variant="warning">
          Mrzí nás to, ale nepodařilo se nám zpracovat Váš požadavek. Zkuste to
          prosím později znovu.
        </Alert>
      );
    }
  }

  render() {
    return this.state.sent ? this.renderResult() : this.renderForm();
  }
}

export default ContactForm;
